import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../styles/InputStyles";
import { StyledFlexItem, StyledFlexRow } from "../../../styles/LayoutStyles";
import Select from "../../../components/select/main/Select";
import MultiSelect from "../../../components/select/main/multi/MultiSelect";
import EntityTranslationsContainer from "../../../components/entityTranslationsContainer/EntityTranslationsContainer";

function VisitorTypePageView({
  visitorTypeData,
  departments,
  fields,
  flows,
  layouts,
  onDepartmentChange,
  onNameChange,
  onOrderChange,
  onEarliestTimeChange,
  onLatestTimeChange,
  onPrintCountChange,
  onReturningChange,
  onRequiresApprovalChange,
  onValidityFieldsChange,
  onSignInFlowChange,
  onSignOutFlowChange,
  onLayoutChange,
  onCancel,
  onSubmit,
  translationsController,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader groups="visitor_type_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"visitor_type_page_submit"}
        >
          <StyledFlexRow style={{ marginTop: theme.spacing.normal }}>
            <StyledFlexItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.name")}
                error={EVAApiHelper.getResponseError("name", submitError)}
                margin={`0px 0px ${theme.spacing.normal}`}
                required
              >
                <StyledInput
                  type="text"
                  placeholder={translate("eva.main.validation.attributes.name")}
                  value={visitorTypeData.name}
                  onChange={onNameChange}
                />
              </InputWrapper>
            </StyledFlexItem>

            <InputWrapper
              label={translate("eva.main.validation.attributes.order")}
              error={EVAApiHelper.getResponseError("order", submitError)}
            >
              <StyledInput
                type="number"
                placeholder={translate("eva.main.validation.attributes.order")}
                value={visitorTypeData.order}
                onChange={onOrderChange}
              />
            </InputWrapper>
          </StyledFlexRow>

          <StyledFlexRow style={{ marginBottom: theme.spacing.normal }}>
            <StyledFlexItem>
              <InputWrapper
                label={translate(
                  "eva.main.validation.attributes.sign_in_flow_id"
                )}
                required
                error={EVAApiHelper.getResponseError(
                  "sign_in_flow_id",
                  submitError
                )}
              >
                <Select
                  options={flows.map((flow) => {
                    return { value: flow.id, label: flow.name };
                  })}
                  value={visitorTypeData.sign_in_flow_id}
                  onChange={onSignInFlowChange}
                />
              </InputWrapper>
            </StyledFlexItem>

            <StyledFlexItem>
              <InputWrapper
                label={translate(
                  "eva.main.validation.attributes.sign_out_flow_id"
                )}
                error={EVAApiHelper.getResponseError(
                  "sign_out_flow_id",
                  submitError
                )}
              >
                <Select
                  options={flows.map((flow) => {
                    return { value: flow.id, label: flow.name };
                  })}
                  nullable={true}
                  value={visitorTypeData.sign_out_flow_id}
                  onChange={onSignOutFlowChange}
                />
              </InputWrapper>
            </StyledFlexItem>
          </StyledFlexRow>

          <InputWrapper
            label={translate("eva.main.validation.attributes.layout_id")}
            margin={`0px 0px ${theme.spacing.normal}`}
            error={EVAApiHelper.getResponseError("layout_id", submitError)}
          >
            <Select
              options={layouts.map((layout) => {
                return { value: layout.id, label: layout.name };
              })}
              nullable={true}
              value={visitorTypeData.layout_id}
              onChange={onLayoutChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.department_id")}
            error={EVAApiHelper.getResponseError("department_id", submitError)}
            margin={`0px 0px ${theme.spacing.normal}`}
          >
            <Select
              options={departments.map((department) => {
                return { value: department.id, label: department.name };
              })}
              nullable={true}
              value={visitorTypeData.department_id}
              onChange={onDepartmentChange}
            />
          </InputWrapper>

          <StyledFlexRow>
            <StyledFlexItem>
              <InputWrapper
                label={translate(
                  "eva.main.validation.attributes.earliest_time"
                )}
                error={EVAApiHelper.getResponseError(
                  "earliest_time",
                  submitError
                )}
                margin={`0px 0px ${theme.spacing.normal}`}
              >
                <StyledInput
                  type="time"
                  value={visitorTypeData.earliest_time}
                  onChange={onEarliestTimeChange}
                />
              </InputWrapper>
            </StyledFlexItem>

            <StyledFlexItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.latest_time")}
                error={EVAApiHelper.getResponseError(
                  "latest_time",
                  submitError
                )}
                margin={`0px 0px ${theme.spacing.normal}`}
              >
                <StyledInput
                  type="time"
                  value={visitorTypeData.latest_time}
                  onChange={onLatestTimeChange}
                />
              </InputWrapper>
            </StyledFlexItem>
          </StyledFlexRow>

          <InputWrapper
            label={translate("eva.main.validation.attributes.print_count")}
            error={EVAApiHelper.getResponseError("print_count", submitError)}
            margin={`0px 0px ${theme.spacing.normal}`}
          >
            <StyledInput
              type="number"
              value={visitorTypeData.print_count}
              onChange={onPrintCountChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate(
              "eva.main.validation.attributes.requires_approval"
            )}
            error={EVAApiHelper.getResponseError(
              "requires_approval",
              submitError
            )}
            margin={`0px 0px ${theme.spacing.normal}`}
          >
            <Select
              value={visitorTypeData.requires_approval}
              options={[
                { value: true, label: translate("eva.main.general.yes") },
                { value: false, label: translate("eva.main.general.no") },
              ]}
              onChange={onRequiresApprovalChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.returning")}
            error={EVAApiHelper.getResponseError("returning", submitError)}
            margin={`0px 0px ${theme.spacing.normal}`}
          >
            <Select
              value={visitorTypeData.returning}
              options={[
                { value: true, label: translate("eva.main.general.yes") },
                { value: false, label: translate("eva.main.general.no") },
              ]}
              onChange={onReturningChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.validity_fields")}
            error={EVAApiHelper.getResponseErrorArray(
              "validity_fields",
              submitError,
              (index) =>
                fields.find(
                  (field) => field.id === visitorTypeData.validity_fields[index]
                )?.key
            )}
          >
            <MultiSelect
              options={fields.map((field) => {
                return {
                  value: field.id,
                  label: field.key,
                };
              })}
              value={visitorTypeData.validity_fields}
              onChange={onValidityFieldsChange}
            />
          </InputWrapper>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader groups="visitor_type_page_fetch">
        <EntityTranslationsContainer
          controller={translationsController}
          style={{ marginTop: theme.spacing.large }}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.title")}
            error={translationsController.getTranslationError(
              "title",
              submitError
            )}
            margin={`0px 0px ${theme.spacing.normal}`}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.title")}
              value={translationsController.getValue("title")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "title"
                )
              }
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.message")}
            error={translationsController.getTranslationError(
              "message",
              submitError
            )}
          >
            <StyledInput
              as="textarea"
              rows={4}
              placeholder={translate("eva.main.validation.attributes.message")}
              value={translationsController.getValue("message")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "message"
                )
              }
            />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>
    </>
  );
}

export default VisitorTypePageView;

import { faSignOut, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  StyledDashboardContainer,
  StyledDashboardHeader,
  StyledDashboardMain,
  StyledDashboardMainContainer,
  StyledDashboardUserName,
} from "./DashboardStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardNav from "./nav/DashboardNav";
import { StyledIconButton } from "../../styles/ButtonStyles";
import { Outlet } from "react-router-dom";
import { useLanguageContext } from "../../context/LanguageContext";
import LanguageSelect from "../../components/select/custom/language/LanguageSelect";
import LocationSelect from "../../components/select/custom/location/LocationSelect";
import HelpSelect from "../../components/select/custom/help/HelpSelect";
import ThemeSelect from "../../components/select/custom/theme/ThemeSelect";
import { useAuthContext } from "../../context/AuthContext";
import DashboardTitle from "./title/DashboardTitle";
import CalamityActivationButton from "../../components/calamityButton/CalamityActivationButton";
import HiddenLoader from "../../components/loader/HiddenLoader/HiddenLoader";
import { StyledFlexColumn, StyledFlexRow } from "../../styles/LayoutStyles";
import { useTheme } from "styled-components";

function DashboardView() {
  const theme = useTheme();
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  return (
    <StyledDashboardContainer>
      <DashboardNav />

      <StyledDashboardMainContainer>
        {/* Dashboard Header */}
        <StyledDashboardHeader>
          <DashboardTitle />

          <StyledFlexColumn style={{ alignItems: "flex-end" }}>
            {/* Dashboard User Info */}
            <StyledFlexRow style={{ alignItems: "center" }}>
              <FontAwesomeIcon icon={faUser} />
              <StyledDashboardUserName>
                {authContext.auth.user.name}
              </StyledDashboardUserName>
            </StyledFlexRow>

            <StyledFlexRow
              style={{ gap: theme.spacing.small, flexWrap: "nowrap" }}
            >
              <LanguageSelect />
              <LocationSelect />
              <HelpSelect />
              <ThemeSelect />
              <CalamityActivationButton />

              {/* Dashboard User Logout */}
              <HiddenLoader groups="logout">
                <StyledIconButton
                  onClick={() => authContext.logout()}
                  title={translate("eva.main.auth.logout")}
                >
                  <FontAwesomeIcon icon={faSignOut} />
                </StyledIconButton>
              </HiddenLoader>
            </StyledFlexRow>
          </StyledFlexColumn>
        </StyledDashboardHeader>

        {/* Dashboard Main Content */}
        <StyledDashboardMain>
          <Outlet />
        </StyledDashboardMain>
      </StyledDashboardMainContainer>
    </StyledDashboardContainer>
  );
}

export default DashboardView;

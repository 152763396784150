import { useParams } from "react-router-dom";
import FieldOptionPageView from "./FieldOptionPageView";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { useState } from "react";
import { useRouteContext } from "../../../../context/RouteContext";
import useEntityTranslations from "../../../../hooks/useEntityTranslations";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";

function FieldOptionPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { field, field_option } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [fieldOptionData, setFieldOptionData] = useState({
    field_id: field,
    key: "",
    translations: [],
  });

  const translationsController = useEntityTranslations(
    fieldOptionData.translations,
    onTranslationsChange
  );

  //UseEffect to initialize the component
  useCustomEffect(() => {
    if (field_option) {
      fetchFieldOptionData();
    }
  });

  /**
   * Fetch the field option data from the API
   */
  async function fetchFieldOptionData() {
    await callEvaApi(
      new EvaApiCall(`field_options/${field_option}`)
        .setLoadingGroup("field_option_page_fetch")
        .setOnSuccess((response) => {
          const newData = response.data.data;
          setFieldOptionData({
            field_id: field,
            key: newData.key,
            translations: newData.translations.map((translation) => ({
              language_id: translation.language,
              label: translation.label,
            })),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will handle the key change
   * @param {event} e
   */
  function onKeyChange(e) {
    setFieldOptionData((current) => ({ ...current, key: e.target.value }));
  }

  /**
   * Handle the translations change
   * @param {object[]} value
   */
  function onTranslationsChange(value) {
    //Update the translations
    setFieldOptionData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(
        field_option ? `field_options/${field_option}` : `field_options`
      )
        .setLoadingGroup("field_option_page_save")
        .setMethod(field_option ? "PUT" : "POST")
        .setErrorState(setSubmitError)
        .setData(fieldOptionData)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <FieldOptionPageView
      fieldOptionData={fieldOptionData}
      translationsController={translationsController}
      onKeyChange={onKeyChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default FieldOptionPage;

import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledInput } from "../../../styles/InputStyles";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import {
  StyledFlexColumn,
  StyledFlexItem,
  StyledFlexRow,
} from "../../../styles/LayoutStyles";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import LocationsDataTable from "../../../components/dataTable/tables/LocationsDataTable";
import Select from "../../../components/select/main/Select";

function CompanyPageView({
  company,
  companyData,
  onNameChange,
  onContactChange,
  onContactDetailsChange,
  onLicenseChange,
  onActiveChange,
  onUsePrintChange,
  onRemarkChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledFlexColumn style={{ gap: theme.spacing.large }}>
      <OverlayLoader groups="company_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"company_page_save"}
        >
          <StyledFlexColumn>
            <InputWrapper
              label={translate("eva.main.validation.attributes.name")}
              error={EVAApiHelper.getResponseError("name", submitError)}
              required
            >
              <StyledInput
                type="text"
                placeholder={translate("eva.main.validation.attributes.name")}
                value={companyData.name}
                onChange={onNameChange}
              />
            </InputWrapper>

            <StyledFlexRow>
              <StyledFlexItem>
                <InputWrapper
                  label={translate("eva.main.validation.attributes.contact")}
                  error={EVAApiHelper.getResponseError("contact", submitError)}
                  required
                >
                  <StyledInput
                    type="text"
                    placeholder={translate(
                      "eva.main.validation.attributes.contact"
                    )}
                    value={companyData.contact}
                    onChange={onContactChange}
                  />
                </InputWrapper>
              </StyledFlexItem>

              <StyledFlexItem>
                <InputWrapper
                  label={translate(
                    "eva.main.validation.attributes.contact_details"
                  )}
                  error={EVAApiHelper.getResponseError(
                    "contact_details",
                    submitError
                  )}
                >
                  <StyledInput
                    type="text"
                    placeholder={translate(
                      "eva.main.validation.attributes.contact_details"
                    )}
                    value={companyData.contact_details}
                    onChange={onContactDetailsChange}
                  />
                </InputWrapper>
              </StyledFlexItem>
            </StyledFlexRow>

            <StyledFlexRow>
              <StyledFlexItem>
                <InputWrapper
                  label={translate("eva.main.validation.attributes.license")}
                  required={true}
                  error={EVAApiHelper.getResponseError("license", submitError)}
                >
                  <Select
                    value={companyData.license}
                    options={[
                      {
                        value: "basic",
                        label: translate("eva.main.licenses.basic"),
                      },
                      {
                        value: "pro",
                        label: translate("eva.main.licenses.pro"),
                      },
                      {
                        value: "enterprise",
                        label: translate("eva.main.licenses.enterprise"),
                      },
                    ]}
                    onChange={onLicenseChange}
                  />
                </InputWrapper>
              </StyledFlexItem>

              <InputWrapper
                label={translate("eva.main.validation.attributes.active")}
                required={true}
                error={EVAApiHelper.getResponseError("active", submitError)}
                style={{ minWidth: "150px" }}
              >
                <Select
                  value={companyData.active}
                  options={[
                    {
                      value: true,
                      label: translate("eva.main.general.yes"),
                    },
                    {
                      value: false,
                      label: translate("eva.main.general.no"),
                    },
                  ]}
                  onChange={onActiveChange}
                />
              </InputWrapper>
            </StyledFlexRow>

            <InputWrapper
              label={translate("eva.main.validation.attributes.use_print")}
              required={true}
              error={EVAApiHelper.getResponseError("use_print", submitError)}
            >
              <Select
                value={companyData.use_print}
                options={[
                  {
                    value: true,
                    label: translate("eva.main.general.yes"),
                  },
                  {
                    value: false,
                    label: translate("eva.main.general.no"),
                  },
                ]}
                onChange={onUsePrintChange}
              />
            </InputWrapper>

            <InputWrapper
              label={translate("eva.main.validation.attributes.remark")}
              error={EVAApiHelper.getResponseError("remark", submitError)}
            >
              <StyledInput
                as={"textarea"}
                value={companyData.remark}
                onChange={onRemarkChange}
              />
            </InputWrapper>
          </StyledFlexColumn>
        </FormContainer>
      </OverlayLoader>

      {companyData.fetched ? <LocationsDataTable companyId={company} /> : ""}
    </StyledFlexColumn>
  );
}

export default CompanyPageView;

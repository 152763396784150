import styled from "styled-components";
import { StyledFlexColumn } from "../../styles/LayoutStyles";

export const StyledQuestionnaireWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.large};
  padding-top: ${(props) =>
    `calc(${props.theme.spacing.large} + ${props.theme.spacing.normal})`};
  border-radius: ${(props) => props.theme.radius.normal};
  background-color: ${(props) => props.theme.colors.primary.lightest};
  position: relative;
  margin-top: ${(props) => props.theme.spacing.large};
`;

export const StyledQuestionnaire = styled.p`
  font-weight: bold;
  background-color: ${(props) => props.theme.colors.primary.normal};
  padding: 5px;
  border-radius: ${(props) => props.theme.radius.normal};
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => `translate(${props.theme.spacing.large}, -50%)`};
  border: 3px solid ${(props) => props.theme.colors.primary.lightest};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${(props) => `calc(100% - ${props.theme.spacing.large} * 2)`};
`;

export const StyledQuestionWrapper = styled(StyledFlexColumn)`
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary.light};
    padding-bottom: ${(props) => props.theme.spacing.normal};
  }
`;

import { useLanguageContext } from "../../context/LanguageContext";
import VisitorsLogPage from "../../pages/visitors/log/VisitorsLogPage";
import { useVisitorLogsRouteValidator } from "../validators/VisitorLogsRouteValidator";
import { RouteConfig } from "./RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useVisitorLogsRouteConfig() {
  const { translate } = useLanguageContext();
  return new RouteConfig()
    .setPath("/dashboard/visitor_logs")
    .setLabel(translate("eva.main.entities.visitor_logs"))
    .setValidator(useVisitorLogsRouteValidator())
    .setPage(<VisitorsLogPage />);
}

export default useVisitorLogsRouteConfig;

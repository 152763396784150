const LightTheme = {
  colors: {
    primary: {
      darkest: "#ffffff", // Heel licht wit voor de achtergrond
      darkestRGB: "255, 255, 255",
      dark: "#f3f4f6", // Iets donkerder wit voor secundaire elementen
      normal: "#e5e7eb", // Lichte grijstint voor borders of panelen
      light: "#d1d5db", // Voor hover-effecten of inactieve gebieden
      lightest: "#cbd5e1", // Lichtere grijstint die beter zichtbaar is
      lightestRGB: "224, 228, 233",
    },

    accent: {
      dark: "#f97316",
      normal: "#fb923c",
      normalRGB: "251, 146, 60",
    },

    error: "#fb923c",

    on: {
      primary: "#444444",
      primaryRGB: "68, 68, 68",
      accent: "white",
    },
  },

  container: {
    size: {
      normal: "30px",
    },
  },

  spacing: {
    small: "3.5px",
    normal: "7.5px",
    large: "20px",
  },

  font: {
    size: {
      sm: "10.5px",
      normal: "13.5px",
      lg: "20.25px",
      xl: "27px",
    },
  },

  radius: {
    small: "6.75px",
    normal: "10px",
  },

  shadow: {
    default:
      "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  },

  logo: "logo_orange.png",
};

export default LightTheme;

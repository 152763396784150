import styled from "styled-components";
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../styles/CardStyles";

export const StyledOverlay = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.66);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing.large};
`;

export const StyledModal = styled(StyledCard)`
  min-width: 500px;
  width: ${(props) => (props.$responsiveWidth === "true" ? "auto" : "500px")};
  max-width: 100%;
  max-height: 100%;
`;

export const StyledModalHeader = styled(StyledCardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledModalBody = styled(StyledCardBody)`
  max-height: ${(props) => `calc(100% - ${props.theme.spacing.large} * 2)`};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px;
`;

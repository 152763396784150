import { createContext, useContext, useState } from "react";
import { useAppContext } from "./AppContext";
import useEvaApi, { EvaApiCall } from "../hooks/useEvaApi";
import useCustomEffect from "../hooks/useCustomEffect";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";

const TerminalContext = createContext();

export function TerminalProvider({ children }) {
  const { contexts } = useAppContext();
  const callEvaApi = useEvaApi();
  const [data, setData] = useState({
    terminals: [],
    terminalId: null,
    terminal: null,
  });

  /**
   * UseEffect for fetching the terminals when the user role_type changes
   */
  useCustomEffect(() => {
    fetchTerminals();
  }, [contexts.authContext?.auth?.user?.role_type]);

  /**
   * UseEffect for fetching the terminal when the terminalId changes
   */
  useCustomEffect(() => {
    fetchTerminal();
  }, [data.terminalId]);

  /**
   * This method will fetch the terminals from the EVA API
   */
  async function fetchTerminals() {
    if (
      !contexts.authContext?.auth?.user ||
      contexts.authContext?.auth?.user?.role_type !== "terminal"
    ) {
      setData((prev) => ({
        ...prev,
        terminals: [],
        terminal: null,
        terminalId: null,
      }));
      return;
    }

    await callEvaApi(
      new EvaApiCall("terminals")
        .setLoadingGroup("APP")
        .setBlockAppRender(true)
        .setOnSuccess((response) => {
          const newTerminals = response.data.data.records;
          setData((prev) => ({
            ...prev,
            terminals: newTerminals,
            terminalId: newTerminals.find(
              (terminal) =>
                parseInt(terminal.id) ===
                parseInt(localStorage.getItem("terminal_id"))
            )?.id,
          }));
        })
        .setAlertError(false)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will fetch the terminal with full data from the EVA API based on the terminalId
   */
  async function fetchTerminal() {
    if (!data.terminalId) {
      setData((prev) => ({ ...prev, terminal: null }));
      return;
    }

    await callEvaApi(
      new EvaApiCall(`terminals/${data.terminalId}`)
        .setLoadingGroup("APP")
        .setBlockAppRender(true)
        .setParams({
          with_terminal_buttons_data: true,
          with_terminal_visitor_types_data: true,
          with_terminal_screensavers_data: true,
          with_terminal_layout_data: true,
          with_layout_layout_data: true,
          with_layout_item_children_data: true,
          with_layout_item_image_data: true,
          with_button_layout_data: true,
          with_visitor_type_layout_data: true,
        })
        .setOnSuccess((response) => {
          setData((prev) => ({
            ...prev,
            terminal: response.data.data,
          }));
        })
        .setAlertError(false)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will update the terminal based on the terminalId
   * @param {int} terminalId
   */
  async function updateTerminal(terminalId) {
    localStorage.setItem("terminal_id", terminalId);
    setData((prev) => ({
      ...prev,
      terminalId: terminalId,
    }));
  }

  return useContextDefiner(
    new ContextDefinition("terminalContext", TerminalContext)
      .setData({
        terminals: data.terminals,
        terminal: data.terminal,
        updateTerminal,
      })
      .setChildren(children)
  );
}

export function useTerminalContext() {
  return useContext(TerminalContext);
}

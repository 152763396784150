import { useAuthContext } from "../context/AuthContext";

function useGuestMiddleware() {
  const authContext = useAuthContext();

  /**
   * This method will validate the middleware
   * @returns {boolean}
   */
  function validate() {
    return authContext.auth.user === null;
  }

  /**
   * This method return the redirect instructions on fail
   * @returns {{ to: string, replace: boolean }}
   */
  function redirect() {
    if (authContext.auth.user?.role_type === "terminal") {
      return { to: "/frontoffice", replace: true };
    }

    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get("redirect");
    return { to: redirect ?? "/dashboard", replace: true };
  }

  //return the middleware
  return { validate, redirect };
}

export default useGuestMiddleware;

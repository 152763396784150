import axios from "axios";
import { STATIC_AUTH, STATIC_UPDATE_AUTH } from "../context/AuthContext";
import { STATIC_LANGUAGE } from "../context/LanguageContext";

/**
 * The default axios configuration for fetching the EVA API
 */
const EVAApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/`,
  withCredentials: true,
});

/**
 * The request interceptor for the EVA API which will automatically set the authorization token
 */
export const AuthorizedRequestInterceptor = EVAApi.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"] && STATIC_AUTH?.token) {
      config.headers["Authorization"] = `Bearer ${STATIC_AUTH.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * The request interceptor for the EVA API which will automatically set the Content-Language
 */
export const TranslatedRequestInterceptor = EVAApi.interceptors.request.use(
  (config) => {
    if (!config.headers["Content-Language"] && STATIC_LANGUAGE?.language) {
      config.headers["Content-Language"] = STATIC_LANGUAGE.language.code;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * The request interceptor for the EVA API which will automatically set the Content-Language
 */
export const LocationRequestInterceptor = EVAApi.interceptors.request.use(
  (config) => {
    if (!config.headers["Location-Id"] && STATIC_AUTH?.location) {
      config.headers["Location-Id"] = STATIC_AUTH.location;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * This request interceptor will set the timezone offset for each API request
 */
export const TimezoneRequestInterceptor = EVAApi.interceptors.request.use(
  (config) => {
    if (!config.headers["Timezone"]) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.headers["Timezone"] = timezone;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * The response interceptor for the EVA API which will automatically logout the current user when the API returns a unautorized response
 */
export const UnauthorizedResponseInterceptor = EVAApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !originalRequest._isRefreshCall
    ) {
      //If we are already refreshing the token we will add the request to the queue and resolve it when the token is refreshed
      if (isRefreshingToken) {
        return new Promise((resolve, reject) => {
          unauthorizedRequestsQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return EVAApi(originalRequest);
          })
          .catch((queueError) => {
            return Promise.reject(queueError);
          });
      }

      //Set the retry flag and the token refreshing flag
      originalRequest._retry = true;
      isRefreshingToken = true;

      //Try to refresh the token
      try {
        const response = await EVAApi.post("refresh_token", null, {
          _isRefreshCall: true, //This flag will prevent the interceptor from intercepting the request
        });

        //Get the tokens from the response
        const newTokens = response.data.data.tokens;

        //Update the token in the state
        STATIC_UPDATE_AUTH((prev) => ({
          ...prev,
          token: newTokens.access.token,
        }));

        //Use the new access token in the original request
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${newTokens.access.token}`;

        //Process the unauthorized requests queue with the new access token
        processUnauthorizedRequestsQueue(null, newTokens.access.token);

        //Return the original request
        return EVAApi(originalRequest);
      } catch (refreshError) {
        //Process the unauthorized requests queue with the error
        processUnauthorizedRequestsQueue(refreshError, null);
      } finally {
        //Reset the token refreshing flag
        isRefreshingToken = false;
      }

      //If we reach this point, it means the refresh token is invalid or missing, so we will logout the user
      STATIC_UPDATE_AUTH((prev) => ({ ...prev, user: null, token: null }));
    }

    //Return the error if it is not a 401 error or if the refresh token has failed
    return Promise.reject(error);
  }
);

//The required variables to handle the token refreshing
let isRefreshingToken = false;
let unauthorizedRequestsQueue = [];

/**
 * This method will process the unauthorized requests queue
 * @param {any} error
 * @param {string} token
 */
function processUnauthorizedRequestsQueue(error, token) {
  unauthorizedRequestsQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  unauthorizedRequestsQueue = [];
}

export default EVAApi;

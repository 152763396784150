import { createContext, useContext, useState } from "react";
import useEvaApi, { EvaApiCall } from "../hooks/useEvaApi";
import { useAppContext } from "./AppContext";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";
import useCustomEffect from "../hooks/useCustomEffect";

const ParameterContext = createContext();

export function ParameterProvider({ children }) {
  const { contexts } = useAppContext();
  const callEvaApi = useEvaApi();
  const [parameters, setParameters] = useState({});

  /**
   * UseEffect for fetching the parameters when the user location changes
   */
  useCustomEffect(() => {
    fetchParameters();
  }, [contexts.authContext?.auth?.location]);

  /**
   * This callback will fetch the parameters from the EVA API
   */
  async function fetchParameters() {
    if (
      !contexts.authContext?.auth?.user ||
      contexts.authContext?.auth?.user?.company?.type === "superadmin"
    ) {
      return;
    }

    await callEvaApi(
      new EvaApiCall("parameters")
        .setLoadingGroup("APP")
        .setBlockAppRender(true)
        .setOnSuccess((response) => {
          setParameters(getParametersDictionary(response.data.data.records));
        })
        .setAlertError(false)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will return the parameters dictionary
   * @param {object[]} parameters
   * @returns {object}
   */
  function getParametersDictionary(parameters) {
    const dictionary = {};
    parameters.forEach((parameter) => {
      dictionary[parameter.key] = parameter;
    });
    return dictionary;
  }

  /**
   * This method will return the parameter by key
   * @param {string} key
   * @returns {any}
   */
  function getParameter(key) {
    return parameters[key];
  }

  return useContextDefiner(
    new ContextDefinition("parameterContext", ParameterContext)
      .setData({ parameters, fetchParameters, getParameter })
      .setChildren(children)
  );
}

export function useParameterContext() {
  return useContext(ParameterContext);
}

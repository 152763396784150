import { useState } from "react";
import { useLanguageContext } from "../context/LanguageContext";
import EVAApiHelper from "../helpers/EVAApiHelper";

function useEntityTranslations(translations, onChange) {
  const languageContext = useLanguageContext();
  const [languageId, setLanguageId] = useState(languageContext.language.id);
  const currentTranslationIndex = translations.findIndex(
    (trans) => trans.language_id === languageId
  );
  const currentTranslation = translations[currentTranslationIndex];

  /**
   * This method will handle the language change event.
   * @param {any} value
   */
  function onLanguageChange(value) {
    if (languageId !== value) {
      setLanguageId(value);
    }
  }

  /**
   * This method will return the translation value for the given key.
   * @param {string} key
   * @returns {string}
   */
  function getValue(key) {
    return currentTranslation?.[key] || "";
  }

  /**
   * This method will handle the entity translation change event for the given key.
   * @param {any} value
   * @param {string} key
   */
  function onTranslationChange(value, key) {
    //Copy the translations and find the index of the translation
    const newTranslations = [...translations];
    const index = newTranslations.findIndex(
      (trans) => trans.language_id === languageId
    );

    //If the value is a file, then make sure to include the language id in the file name
    if (
      value instanceof File ||
      (Array.isArray(value) && value[0] instanceof File)
    ) {
      value = Array.isArray(value)
        ? value.map((file) => convertFileTranslation(file))
        : convertFileTranslation(value);
    }

    //Add the translation if the index was not found
    if (index === -1) {
      if (value && value.length > 0) {
        newTranslations.push({
          language_id: languageId,
          [key]: value,
        });
      }
    }
    //Update or delete the translation if the index was found
    else {
      newTranslations[index][key] = value;

      //Remove translation key if the value is empty
      if (
        !newTranslations[index][key] ||
        newTranslations[index][key].length === 0
      ) {
        delete newTranslations[index][key];
      }

      //Remove translation if all keys are empty
      if (Object.keys(newTranslations[index]).length === 1) {
        newTranslations.splice(index, 1);
      }
    }

    //Update the translations
    if (onChange) {
      onChange(newTranslations);
    }
  }

  /**
   * This method will make sure the file name contains the language id.
   * @param {File} file
   * @returns {File}
   */
  function convertFileTranslation(file) {
    const fileNameParts = file.name.split(".");
    return new File(
      [file],
      `${fileNameParts[0]} [_${languageId}_].${fileNameParts[1]}`
    );
  }

  /**
   * This method will return the translation error for the given key.
   * @param {string} key
   * @param {object} submitError
   * @returns {string}
   */
  function getTranslationError(key, submitError) {
    let error = EVAApiHelper.getResponseErrorArray(
      `translations.*.${key}`,
      submitError,
      (index) => {
        const languageIndex = languageContext.languages.findIndex(
          (lang) => lang.id === translations[index]?.language_id
        );
        if (languageIndex === -1) {
          return "";
        } else {
          return languageContext.languages[languageIndex].label;
        }
      }
    );

    //Remove empty translation language tags
    if (error) {
      error = error.replace("<strong><em></em></strong>", "");
      if (error.includes("()")) {
        error = null;
      }
    }

    return error;
  }

  return {
    languageId,
    getValue,
    onLanguageChange,
    onTranslationChange,
    getTranslationError,
  };
}

export default useEntityTranslations;

import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledInput } from "../../../styles/InputStyles";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import MultiSelect from "../../../components/select/main/multi/MultiSelect";
import Select from "../../../components/select/main/Select";
import LocationFormSelect from "../../../components/select/custom/location/form/LocationFormSelect";
import { useAuthContext } from "../../../context/AuthContext";

function EmployeePageView({
  employee,
  employeeData,
  allergens,
  departments,
  onLocationIdChange,
  onNameChange,
  onEmailChange,
  onPresentChange,
  onAllergensChange,
  onDepartmentChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  return (
    <OverlayLoader groups="employee_page_fetch">
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"employee_page_save"}
      >
        <StyledFlexColumn>
          <InputWrapper
            label={translate("eva.main.entities.location")}
            error={EVAApiHelper.getResponseError("location_id", submitError)}
          >
            <LocationFormSelect
              value={employeeData.location_id}
              onChange={onLocationIdChange}
              disabled={employee !== undefined}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            required
            error={EVAApiHelper.getResponseError("name", submitError)}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.name")}
              value={employeeData.name}
              onChange={onNameChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.email")}
            required
            error={EVAApiHelper.getResponseError("email", submitError)}
          >
            <StyledInput
              type="email"
              placeholder={translate("eva.main.validation.placeholders.email")}
              value={employeeData.email}
              onChange={onEmailChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.present")}
            error={EVAApiHelper.getResponseError("present", submitError)}
          >
            <Select
              options={[
                { value: true, label: translate("eva.main.general.yes") },
                { value: false, label: translate("eva.main.general.no") },
              ]}
              value={employeeData.present}
              onChange={onPresentChange}
              disabled={
                employee && !authContext.hasPermission("employees.update")
              }
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.department_id")}
            error={EVAApiHelper.getResponseError("department_id", submitError)}
          >
            <Select
              options={departments.map((department) => {
                return { value: department.id, label: department.name };
              })}
              nullable={true}
              value={employeeData.department_id}
              onChange={onDepartmentChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.entities.allergens")}
            margin={`0px 0px ${theme.spacing.normal} 0px`}
            error={EVAApiHelper.getResponseErrorArray(
              "allergens",
              submitError,
              (index) =>
                translate(`eva.main.allergens.${allergens[index].name}`)
            )}
          >
            <MultiSelect
              options={allergens.map((allergen) => {
                return {
                  value: allergen.id,
                  label: translate(`eva.main.allergens.${allergen.name}`),
                };
              })}
              value={employeeData.allergens}
              onChange={onAllergensChange}
            />
          </InputWrapper>
        </StyledFlexColumn>
      </FormContainer>
    </OverlayLoader>
  );
}

export default EmployeePageView;

import { useLanguageContext } from "../../../context/LanguageContext";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../context/AuthContext";
import useCompanyEditRouteConfig from "../../../routes/configs/companies/CompanyEditRouteConfig";
import useCompanyCreateRouteConfig from "../../../routes/configs/companies/CompanyCreateRouteConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import RowActionConfig from "../main/config/rowAction/RowActionConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";
import useBooleanColumnConfig from "../main/config/column/default/BooleanColumnConfig";

function CompaniesDataTable() {
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("companies");

  //Set the datatable fetch info
  config.setFetchRequest("companies");

  //Set the datatable order by
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("name").setLockActive(true),
    useBasicColumnConfig("contact"),
    useBasicColumnConfig("contact_details"),
    useBasicColumnConfig("license.translation").setData((row) =>
      translate(`eva.main.licenses.${row.license}`)
    ),
    useBooleanColumnConfig("active"),
    useBooleanColumnConfig("use_print").setActive(false),
    useBasicColumnConfig("remark").setActive(false),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    new RowActionConfig()
      .setIcon(faSignInAlt)
      .setCallback((row) => authContext.loginToCompany(row.id))
      .setLoadingGroups(["login_to_company"])
      .setTitle(translate("eva.main.auth.company_login")),

    useEditRowActionConfig("company", useCompanyEditRouteConfig()),
    useDeleteRowActionConfig("company", null, true),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("company", useCompanyCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default CompaniesDataTable;

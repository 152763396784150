import styled from "styled-components";
import { MultiStyle } from "../context/ThemeContext";

export const StyledInput = styled.input`
  ${(props) =>
    MultiStyle.new(props, "background-color")
      .setBack(props.theme.colors.primary.lightest)
      .setFront(props.theme["settings.input.color.primary.background"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.on.primary)
      .setFront(props.theme["settings.input.color.primary.text"] ?? "inherit")
      .get()}
  ${(props) =>
    MultiStyle.new(props, "padding")
      .setBack(props.theme.spacing.normal)
      .setFront(`${props.theme["settings.input.padding"]}px`)
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border-radius")
      .setBack(props.theme.radius.normal)
      .setFront(`${props.theme["settings.input.border.radius"]}px`)
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border-color")
      .setBack("transparent")
      .setFront(props.theme["settings.input.color.primary.border"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "width").setBack(props.width).setFront("100%").get()}
  ${(props) =>
    MultiStyle.new(props, "font-size")
      .setFront(`${props.theme["settings.input.font.size"]}px`)
      .get()}

  outline: none;
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.height};
  font-weight: initial;
  opacity: ${(props) => (props.disabled ? 0.33 : 1)};
  border-width: 1px;
  border-style: solid;

  &::placeholder {
    ${(props) =>
      MultiStyle.new(props, "color")
        .setBack(props.theme.colors.on.primary)
        .setFront(props.theme["settings.input.color.primary.text"])
        .get()}
    opacity: 0.3;
  }

  &:hover {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setFront(props.theme["settings.input.color.primary.background.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setFront(props.theme["settings.input.color.primary.text.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "border-color")
        .setBack(props.theme.colors.accent.dark)
        .setFront(props.theme["settings.input.color.primary.border.focus"])
        .get()}
  }

  &:focus {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setFront(props.theme["settings.input.color.primary.background.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setFront(props.theme["settings.input.color.primary.text.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "border-color")
        .setBack(props.theme.colors.accent.normal)
        .setFront(props.theme["settings.input.color.primary.border.focus"])
        .get()}
  }
`;

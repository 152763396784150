import { useMemo, useRef, useState } from "react";
import SelectView from "./SelectView";
import { useLanguageContext } from "../../../context/LanguageContext";
import useCustomEffect from "../../../hooks/useCustomEffect";

function Select({
  value,
  options = [],
  nullable = false,
  searchable = null,
  unfocusOnChange = true,
  disabled = false,
  sort = true,
  onChange = (val) => {},
  as,
  hideCaret,
  valueVisual,
  placeholder,
  title,
  style,
}) {
  const { translate } = useLanguageContext();
  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState("");
  const selectRef = useRef(null);
  const optionsRef = useRef(null);
  const optionPositioningRef = useRef(null);
  const searchRef = useRef(null);
  const avoidSearchFocusEvents = useRef(false);

  // Sort the options by label
  const sortedOptions = useMemo(() => {
    return sort
      ? [...options].sort((a, b) => {
          return `${a.label}`.localeCompare(`${b.label}`);
        })
      : options;
  }, [options, sort]);

  // Add the null option
  if (nullable && sortedOptions[0]?.value !== null) {
    sortedOptions.unshift({
      value: null,
      label: translate("eva.main.general.select"),
    });
  }

  // Get the selected option
  const selectedOption = useMemo(() => {
    return (
      sortedOptions.find((option) => option.value === value) ??
      (nullable ? sortedOptions[0] : null)
    );
  }, [sortedOptions, value, nullable]);

  // Filter the options by search
  const filteredOptions = useMemo(() => {
    return search
      ? sortedOptions.filter((option) => {
          return `${option.label}`.toLowerCase().includes(search.toLowerCase());
        })
      : sortedOptions;
  }, [search, sortedOptions]);

  //Determine the default searchable value
  if (searchable === null) {
    searchable =
      sortedOptions.length >= 10 &&
      selectRef?.current !== null &&
      selectRef.current.getBoundingClientRect().width > 200;
  }

  // Create the options portal
  let optionsPortal = document.getElementById("select-options-portal");
  if (!optionsPortal) {
    const portal = document.createElement("div");
    portal.id = "select-options-portal";
    optionsPortal = portal;
    document.body.appendChild(portal);
  }

  /**
   * UseEffect for managing the options repositioning on window events
   */
  useCustomEffect(() => {
    window.addEventListener("wheel", repositionOptions);
    window.addEventListener("mousedown", onMouseDown);
    return () => {
      window.removeEventListener("wheel", repositionOptions);
      window.removeEventListener("mousedown", onMouseDown);
    };
  });

  /**
   * This method will handle the mouse down event
   * @param {event} e
   */
  function onMouseDown(e) {
    if (
      (optionsRef.current && optionsRef.current.contains(e.target)) ||
      (selectRef.current && selectRef.current.contains(e.target))
    ) {
      avoidSearchFocusEvents.current = true;
    }
  }

  /**
   * This method will handle the change event
   * @param {any} newValue
   */
  function onValueChange(newValue) {
    if (newValue !== value) {
      onChange(newValue);

      if (unfocusOnChange) {
        updateFocused(false);
      }
    }
  }

  /**
   * This method will handle the select click event
   */
  function onSelectClick(e) {
    if (optionsRef.current && optionsRef.current.contains(e.target)) return;
    updateFocused(!focused);
  }

  /**
   * This method will handle the search change event
   * @param {event} e
   */
  function onSearchChange(e) {
    if (!searchable) return;
    setSearch(e.target.value);
  }

  /**
   * This method will handle the search confirm event
   * @param {event} e
   */
  function onSearchConfirm(e) {
    if (e.key === "Enter" && searchable) {
      onValueChange(filteredOptions[0]?.value);
    }
  }

  /**
   * This method will handle the focus event of the search input
   */
  function onSearchFocus() {
    if (avoidSearchFocusEvents.current) {
      avoidSearchFocusEvents.current = false;
      return;
    }

    updateFocused(true, false);
  }

  /**
   * This method will handle the blur event of the search input
   */
  function onSearchBlur() {
    if (avoidSearchFocusEvents.current) {
      searchRef.current.focus();
      avoidSearchFocusEvents.current = false;
      return;
    }

    updateFocused(false, false);
  }

  /**
   * This method will update the focused state
   * @param {bool} value
   * @param {bool} affectInput If true, it will focus or blur the input
   */
  function updateFocused(value, affectInput = true) {
    if (value !== focused) {
      setFocused(value);

      //Reset the options style opacity for repositioning
      if (optionsRef.current) {
        optionsRef.current.style.opacity = "0";
      }

      //Reset the search value
      if (!value) {
        setSearch("");
      }

      //Focus or blur the input
      if (affectInput) {
        value ? searchRef.current.focus() : searchRef.current.blur();
      }
    }
  }

  /**
   * This method will reposition the options
   */
  function repositionOptions() {
    const select = selectRef.current;
    const options = optionsRef.current;

    if (!select || !options || !focused) return;

    options.style.opacity = options.style.opacity === "1" ? "1" : "0";

    //Reposition after the next render
    cancelAnimationFrame(optionPositioningRef.current);
    optionPositioningRef.current = requestAnimationFrame(() => {
      const selectRect = select.getBoundingClientRect();
      const optionsRect = options.getBoundingClientRect();

      //Set the initial position
      options.style.left = `${selectRect.left}px`;
      options.style.top = `${selectRect.top + selectRect.height + 2}px`;
      options.style.minWidth = `${selectRect.width}px`;

      //If the options are already visible and the options are above the select, keep it above to prevent flickering
      if (options.style.opacity === "1" && optionsRect.top < selectRect.top) {
        options.style.top = `${selectRect.top - optionsRect.height - 2}px`;
      }

      optionPositioningRef.current = requestAnimationFrame(() => {
        const optionsRect = options.getBoundingClientRect();

        options.style.opacity = "1";

        //Move the options to the left if it's out of the screen on the right
        if (optionsRect.right > window.innerWidth) {
          options.style.left = `${
            optionsRect.left - (optionsRect.right - window.innerWidth) - 20
          }px`;
        }

        //Move the options to above the select if it's out of the screen on the bottom
        if (optionsRect.bottom > window.innerHeight) {
          options.style.top = `${selectRect.top - optionsRect.height - 2}px`;
        }
      });
    });
  }

  //resposition the options
  repositionOptions();

  return (
    <SelectView
      value={selectedOption}
      search={search}
      searchable={searchable}
      disabled={disabled}
      focused={focused}
      options={filteredOptions}
      searchRef={searchRef}
      selectRef={selectRef}
      optionsRef={optionsRef}
      optionsPortal={optionsPortal}
      onSearchChange={onSearchChange}
      onValueChange={onValueChange}
      onSearchConfirm={onSearchConfirm}
      onSearchFocus={onSearchFocus}
      onSearchBlur={onSearchBlur}
      onSelectClick={onSelectClick}
      as={as}
      hideCaret={hideCaret}
      valueVisual={valueVisual}
      placeholder={placeholder}
      title={title}
      style={style}
    />
  );
}

export default Select;

import StatisticSettings from "./statistics/settings/StatisticSettings";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";

function DashboardPageView({ statistics, setStatistics }) {
  return (
    <StyledFlexColumn>
      <StatisticSettings
        statistics={statistics}
        setStatistics={setStatistics}
      />

      {Object.keys(statistics).map((statistic) => {
        return (
          statistics[statistic].visible &&
          !statistics[statistic].disabled &&
          statistics[statistic].component && (
            <div key={statistic}>{statistics[statistic].component}</div>
          )
        );
      })}
    </StyledFlexColumn>
  );
}

export default DashboardPageView;

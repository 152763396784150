import { useRouteContext } from "../../../context/RouteContext";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  StyledArrow,
  StyledHeader,
  StyledPathStep,
  StyledPathSteps,
} from "./DashboardTitleStyles";
import { Link } from "react-router-dom";

function DashboardTitleView() {
  const routeContext = useRouteContext();

  return (
    <div style={{ overflow: "hidden" }}>
      <StyledHeader>{routeContext.routeConfig?.label}</StyledHeader>

      {routeContext.routeConfig?.pathSteps.length > 1 && (
        <StyledPathSteps>
          {routeContext.routeConfig?.pathSteps.map((config, index) => (
            <span key={config.path}>
              <Link to={config.path}>
                <StyledPathStep>{config.label}</StyledPathStep>
              </Link>

              {index < routeContext.routeConfig.pathSteps.length - 1 && (
                <StyledArrow icon={faArrowRight} />
              )}
            </span>
          ))}
        </StyledPathSteps>
      )}
    </div>
  );
}

export default DashboardTitleView;

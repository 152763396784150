import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  StyledFlexColumn,
  StyledFlexItem,
  StyledFlexRow,
} from "../../../styles/LayoutStyles";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../styles/InputStyles";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import TranslationsDataTable from "../../../components/dataTable/tables/TranslationsDataTable";

function LanguagePageView({
  languageData,
  onCodeChange,
  onLabelChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledFlexColumn style={{ gap: theme.spacing.large }}>
      <OverlayLoader groups="language_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={!languageData.default ? onSubmit : null}
          submitError={submitError}
          submitLoadingGroup={"language_page_save"}
        >
          <StyledFlexRow
            style={{ margin: `0px 0px ${theme.spacing.normal} 0px` }}
          >
            <StyledFlexItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.code")}
                error={EVAApiHelper.getResponseError("code", submitError)}
                required
              >
                <StyledInput
                  type="text"
                  value={languageData.code}
                  placeholder={translate(
                    "eva.main.validation.placeholders.code.language"
                  )}
                  onChange={onCodeChange}
                  disabled={languageData.default}
                />
              </InputWrapper>
            </StyledFlexItem>

            <StyledFlexItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.label")}
                error={EVAApiHelper.getResponseError("label", submitError)}
                required
              >
                <StyledInput
                  type="text"
                  value={languageData.label}
                  placeholder={translate(
                    "eva.main.validation.attributes.label"
                  )}
                  onChange={onLabelChange}
                  disabled={languageData.default}
                />
              </InputWrapper>
            </StyledFlexItem>
          </StyledFlexRow>
        </FormContainer>
      </OverlayLoader>

      {languageData.fetched ? (
        <TranslationsDataTable languageData={languageData} />
      ) : (
        ""
      )}
    </StyledFlexColumn>
  );
}

export default LanguagePageView;

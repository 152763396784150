import styled from "styled-components";

export const StyledStatisticSettingsWrapper = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
`;

export const StyledStatisticSettingsContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: ${(props) =>
    `calc(${props.theme.container.size.normal} + ${props.theme.spacing.normal})`};
  background-color: ${(props) => props.theme.colors.primary.darkest};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  border: ${(props) => `1px solid ${props.theme.colors.primary.lightest}`};
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
`;

export const StyledStatisticSettingsItem = styled.div`
  gap: ${(props) => props.theme.spacing.normal};
  display: flex;
  align-items: center;
`;

import FormContainer from "../../../components/formContainer/FormContainer";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import Select from "../../../components/select/main/Select";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledInput } from "../../../styles/InputStyles";
import { StyledFlexItem, StyledFlexRow } from "../../../styles/LayoutStyles";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTheme } from "styled-components";

function IPLockPageView({
  ip_lockData,
  onNameChange,
  onIpChange,
  onAccessChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader groups="ip_lock_page_fetch">
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"ip_lock_page_submit"}
      >
        <InputWrapper
          label={translate("eva.main.validation.attributes.name")}
          error={EVAApiHelper.getResponseError("name", submitError)}
          required
        >
          <StyledInput
            type="text"
            onChange={onNameChange}
            placeholder={translate("eva.main.validation.attributes.name")}
            value={ip_lockData.name}
          />
        </InputWrapper>

        <StyledFlexRow style={{ marginTop: theme.spacing.normal }}>
          <StyledFlexItem>
            <InputWrapper
              label={translate("eva.main.validation.attributes.ip")}
              error={EVAApiHelper.getResponseError("ip", submitError)}
              required
            >
              <StyledInput
                type="text"
                value={ip_lockData.ip}
                placeholder={translate("eva.main.validation.placeholders.ip")}
                onChange={onIpChange}
              />
            </InputWrapper>
          </StyledFlexItem>

          <InputWrapper
            label={translate("eva.main.validation.attributes.access")}
            style={{ minWidth: "100px" }}
            error={EVAApiHelper.getResponseError("access", submitError)}
            required
          >
            <Select
              value={ip_lockData.access}
              options={[
                { value: true, label: translate("eva.main.general.yes") },
                { value: false, label: translate("eva.main.general.no") },
              ]}
              onChange={onAccessChange}
            />
          </InputWrapper>
        </StyledFlexRow>
      </FormContainer>
    </OverlayLoader>
  );
}

export default IPLockPageView;

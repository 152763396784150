import { useState } from "react";
import useCustomEffect from "../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../hooks/useEvaApi";
import VisitorQuestionsDisplayView from "./visitorQuestionsDisplayView";

function VisitorQuestionsDisplay({
  visitor,
  loadingGroup = "visitor_questions_fetch",
  onClose,
}) {
  const callEvaApi = useEvaApi();
  const [questionnaires, setQuestionnaires] = useState([]);

  /**
   * UseEffect for fetching the questionnaires
   */
  useCustomEffect(() => {
    fetchQuestionnaires();
  }, [visitor]);

  /**
   * Fetches the questionnaires that are not already fetched
   */
  async function fetchQuestionnaires() {
    if (!visitor) return;

    //Check which questionnaire keys are missing
    const missingKeys = Object.keys(visitor.questions).filter(
      (qKey) => !questionnaires.some((q) => q.name === qKey)
    );

    //If all questionnaires are already fetched, return
    if (missingKeys.length === 0) return;

    //Fetch the missing questionnaires
    await callEvaApi(
      new EvaApiCall("questionnaires")
        .setLoadingGroup(loadingGroup)
        .setParams({
          with_questionnaire_questions_data: true,
          with_question_answers_data: true,
          where: [
            {
              column: "name",
              value: missingKeys,
            },
          ],
        })
        .setAlertError(true)
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          setQuestionnaires((prev) => [...prev, ...response.data.data.records]);
        })
    );
  }

  return (
    <VisitorQuestionsDisplayView
      visitor={visitor}
      loadingGroup={loadingGroup}
      onClose={onClose}
      questionnaires={questionnaires}
    />
  );
}

export default VisitorQuestionsDisplay;

import useVisitorTypeEditRouteConfig from "../../../../routes/configs/visitor_types/VisitorTypeEditRouteConfig";
import useVisitorTypeCreateRouteConfig from "../../../../routes/configs/visitor_types/VisitorTypeCreateRouteConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useBooleanColumnConfig from "../../main/config/column/default/BooleanColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";

function VisitorTypesDataTable() {
  //Create the datatable config
  const config = new DataTableConfig("visitor_types");

  //Set the datatable fetch info
  config.setFetchRequest("visitor_types");
  config.setFetchParams({
    with_visitor_type_department_data: true,
    with_visitor_type_sign_in_flow_data: true,
    with_visitor_type_sign_out_flow_data: true,
    with_visitor_type_layout_data: true,
  });

  //Set default order
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //Set the datatable columns
  config.setColumns([
    useBasicColumnConfig("name").setLockActive(true),
    useBasicColumnConfig("department.name"),
    useBasicColumnConfig("sign_in_flow.name"),
    useBasicColumnConfig("sign_out_flow.name"),
    useBasicColumnConfig("layout.name"),
    useBasicColumnConfig("earliest_time").setActive(false),
    useBasicColumnConfig("latest_time").setActive(false),
    useBasicColumnConfig("print_count").setActive(false),
    useBooleanColumnConfig("requires_approval").setActive(false),
    useBooleanColumnConfig("returning").setActive(false),
    useBasicColumnConfig("order"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("visitor_type", useVisitorTypeEditRouteConfig()),
    useDeleteRowActionConfig("visitor_type"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig(
      "visitor_type",
      useVisitorTypeCreateRouteConfig()
    ),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default VisitorTypesDataTable;

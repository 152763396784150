import FlowFields from "../../../components/flowFields/FlowFields";
import FormContainer from "../../../components/formContainer/FormContainer";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import LocationFormSelect from "../../../components/select/custom/location/form/LocationFormSelect";
import Select from "../../../components/select/main/Select";
import { useLanguageContext } from "../../../context/LanguageContext";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";

function VisitorPageView({
  visitorData,
  setVisitorData,
  visitorType,
  visitorTypes,
  onVisitorTypeChange,
  onLocationChange,
  employees,
  onEmployeeIdChange,
  onStatusChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { translate, translateEntity } = useLanguageContext();

  return (
    <OverlayLoader groups={"visitor_page_fetch"}>
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"visitor_page_submit"}
      >
        <StyledFlexColumn>
          <InputWrapper
            label={translate("eva.main.validation.attributes.location_id")}
            required={true}
            error={EVAApiHelper.getResponseError("location_id", submitError)}
          >
            <LocationFormSelect
              value={visitorData.location_id}
              onChange={onLocationChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.visitor_type_id")}
            required={true}
            error={EVAApiHelper.getResponseError(
              "visitor_type_id",
              submitError
            )}
          >
            <Select
              value={visitorData.visitor_type_id}
              options={visitorTypes.map((type) => ({
                value: type.id,
                label: translateEntity(type, "title"),
              }))}
              onChange={onVisitorTypeChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.status")}
            required={true}
            error={EVAApiHelper.getResponseError("status", submitError)}
          >
            <Select
              value={visitorData.status}
              options={[
                {
                  label: translate("eva.main.visitor_statuses.expected"),
                  value: "expected",
                },
                {
                  label: translate("eva.main.visitor_statuses.signed_in"),
                  value: "signed_in",
                  disabled: visitorData.status !== "signed_in",
                },
              ]}
              disabled={visitorData.status === "signed_in"}
              onChange={onStatusChange}
            />
          </InputWrapper>

          {visitorType?.sign_in_flow?.steps.map((step) => {
            switch (step.type) {
              case "fields":
                return (
                  <FlowFields
                    step={step}
                    data={visitorData}
                    setData={setVisitorData}
                    errors={submitError}
                    frontoffice={false}
                  />
                );

              case "employee":
                return (
                  <InputWrapper
                    required={true}
                    label={translate(
                      "eva.main.validation.attributes.employee_id"
                    )}
                    error={EVAApiHelper.getResponseError(
                      "employee_id",
                      submitError
                    )}
                  >
                    <Select
                      value={visitorData.employee_id}
                      options={employees.map((employee) => ({
                        value: employee.id,
                        label: employee.name,
                      }))}
                      onChange={onEmployeeIdChange}
                    />
                  </InputWrapper>
                );

              default:
                return "";
            }
          })}
        </StyledFlexColumn>
      </FormContainer>
    </OverlayLoader>
  );
}

export default VisitorPageView;

import styled from "styled-components";

export const StyledEditor = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
  border-radius: ${(props) => props.theme.radius.normal};
  background-color: ${(props) => props.theme.colors.primary.dark};
`;

export const StyledEditorContent = styled.div.attrs((props) => ({
  style: {
    transform: `translate(${props.position.x}px, ${props.position.y}px) scale(${props.scale})`,
  },
}))`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
`;

export const StyledEditorBackground = styled.div.attrs((props) => ({
  style: {
    backgroundPosition: `${props.position.x}px ${props.position.y}px`,
  },
}))`
  position: absolute;
  transform-origin: top left;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to right,
      rgba(125, 125, 125, 0.15) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(125, 125, 125, 0.15) 1px, transparent 1px);
  background-size: 20px 20px;
`;

export const StyledEditorInfo = styled.div`
  position: absolute;
  top: ${(props) => props.theme.spacing.normal};
  right: ${(props) => props.theme.spacing.normal};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  background-color: rgba(0, 0, 0, 0.33);
`;

export const StyledEditorContentIndicator = styled.div.attrs((props) => ({
  style: {
    transform: `translate(${props.position.x}px, ${props.position.y}px)`,
    width: `${props.size}px`,
    height: `${props.size}px`,
    justifyContent: props.rowAlign ?? "center",
    alignItems: props.colAlign ?? "center",
  },
}))`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  background-color: ${(props) => props.theme.colors.primary.lightest};
  border-radius: 50%;
  padding: ${(props) => props.theme.spacing.small};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.accent.dark};
  }
`;

export const StyledEditorZoomIndicator = styled.div`
  background-color: ${(props) => props.theme.colors.primary.lightest};
  border-radius: ${(props) => props.theme.radius.normal};
  padding: ${(props) => props.theme.spacing.normal};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  opacity: ${(props) => (props.disabled ? 0.33 : 1)};
`;

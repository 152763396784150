import { useLanguageContext } from "../../context/LanguageContext";
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../styles/CardStyles";
import { StyledFlexRow } from "../../styles/LayoutStyles";
import LanguageFormSelect from "../select/custom/language/form/LanguageFormSelect";

function EntityTranslationsContainerView({ controller, children, style }) {
  const { translate } = useLanguageContext();

  return (
    <StyledCard style={style}>
      <StyledCardHeader>
        <StyledFlexRow style={{ alignItems: "center" }}>
          <h2>{translate("eva.main.entities.translations")}</h2>

          <LanguageFormSelect
            value={controller?.languageId}
            onChange={controller?.onLanguageChange}
          />
        </StyledFlexRow>
      </StyledCardHeader>

      <StyledCardBody>{children}</StyledCardBody>
    </StyledCard>
  );
}

export default EntityTranslationsContainerView;

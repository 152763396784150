import styled from "styled-components";

export const StyledOrderPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const StyledOrderPreviewContainer = styled.div`
  position: absolute;
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
  bottom: 0;
  right: 0;
  border-radius: ${(props) => props.theme.radius.normal};
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  box-shadow: ${(props) => props.theme.shadow.default};
  padding: ${(props) => props.theme.spacing.normal};
  margin-right: ${(props) => props.theme.spacing.large};
  margin-bottom: ${(props) => props.theme.spacing.large};
`;

export const StyledDishDisplayImage = styled.img`
  width: 100px;
  height: 100px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const StyledDishInfoDivider = styled.hr`
  margin-block: ${(props) => props.theme.spacing.normal};
`;

export const StyledDishDisplayPrice = styled.p`
  color: ${(props) => props.theme.colors.accent.normal};
`;

export const StyledOrderTableRow = styled.tr`
  border-block: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledOrderTableTd = styled.td`
  height: 40px;
  padding-inline: ${(props) => props.theme.spacing.normal};
  border: 0;
`;

export const StyledOrderTableNameTd = styled(StyledOrderTableTd)`
  width: 100%;
`;

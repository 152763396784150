import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { NAV_FOLD_ON_WIDTH } from "../nav/DashboardNavStyles";

export const StyledHeader = styled.h1`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPathSteps = styled.small`
  color: ${(props) => props.theme.colors.primary.lightest};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${NAV_FOLD_ON_WIDTH}px) {
    display: none;
  }
`;

export const StyledPathStep = styled.b`
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.accent.dark};
  }
`;

export const StyledArrow = styled(FontAwesomeIcon)`
  margin-inline: ${(props) => props.theme.spacing.small};
`;

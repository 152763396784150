import styled from "styled-components";

export const StyledRow = styled.div`
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
  background-color: ${(props) => props.theme.colors.primary.dark};
`;

export const StyledRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
    padding-bottom: ${(props) => props.theme.spacing.normal};
    margin-bottom: ${(props) => props.theme.spacing.normal};
  }
`;

export const StyledRowActions = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  align-items: center;
`;

export const StyledRowFields = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  flex-direction: column;
`;

export const StyledField = styled.div`
  background-color: ${(props) => props.theme.colors.primary.light};
  border-radius: ${(props) => props.theme.radius.normal};
  padding: ${(props) => props.theme.spacing.normal};
`;

export const StyledFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  font-weight: bold;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
    padding-bottom: ${(props) => props.theme.spacing.normal};
    margin-bottom: ${(props) => props.theme.spacing.normal};
  }
`;

export const StyledFieldHeaderLabel = styled.p`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledFieldOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
`;

import { useTheme } from "styled-components";
import QuestionsDataTable from "../../../components/dataTable/tables/questionnaire/QuestionsDataTable";
import FormContainer from "../../../components/formContainer/FormContainer";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import { useLanguageContext } from "../../../context/LanguageContext";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledInput } from "../../../styles/InputStyles";
import { StyledFlexItem, StyledFlexRow } from "../../../styles/LayoutStyles";

function QuestionnairePageView({
  questionnaire,
  questionnaireData,
  onNameChange,
  onScoreChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { translate } = useLanguageContext();
  const theme = useTheme();

  return (
    <>
      <OverlayLoader
        groups="questionnaire_page_fetch"
        containerStyle={{ marginBottom: theme.spacing.large }}
      >
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"questionnaire_page_save"}
        >
          <StyledFlexRow>
            <StyledFlexItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.name")}
                error={EVAApiHelper.getResponseError("name", submitError)}
                required
              >
                <StyledInput
                  value={questionnaireData.name}
                  onChange={onNameChange}
                  placeholder={translate("eva.main.validation.attributes.name")}
                />
              </InputWrapper>
            </StyledFlexItem>

            <InputWrapper
              label={translate("eva.main.validation.attributes.score")}
              error={EVAApiHelper.getResponseError("score", submitError)}
              required
            >
              <StyledInput
                type="number"
                value={questionnaireData.score}
                onChange={onScoreChange}
                placeholder={translate("eva.main.validation.attributes.score")}
              />
            </InputWrapper>
          </StyledFlexRow>
        </FormContainer>
      </OverlayLoader>

      {questionnaireData.fetched && (
        <QuestionsDataTable questionnaire={questionnaire} />
      )}
    </>
  );
}

export default QuestionnairePageView;

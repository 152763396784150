import styled from "styled-components";

export const StyledOverlayLoader = styled.div`
  position: relative;
`;

export const StyledOverlayLoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: ${(props) => props.theme.radius.normal};
  z-index: 10;
`;

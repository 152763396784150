const DarkTheme = {
  colors: {
    primary: {
      darkest: "#111827",
      darkestRGB: "17, 24, 39",
      dark: "#1f2937",
      normal: "#374151",
      light: "#3e4858",
      lightest: "#4b5563",
      lightestRGB: "75, 85, 99",
    },

    accent: {
      dark: "#f97316",
      normal: "#fb923c",
      normalRGB: "251, 146, 60",
    },

    error: "#fb923c",

    on: {
      primary: "white",
      primaryRGB: "255, 255, 255",
      accent: "white",
    },
  },

  container: {
    size: {
      normal: "30px",
    },
  },

  spacing: {
    small: "3.5px",
    normal: "7.5px",
    large: "20px",
  },

  font: {
    size: {
      sm: "10.5px",
      normal: "13.5px",
      lg: "20.25px",
      xl: "27px",
    },
  },

  radius: {
    small: "6.75px",
    normal: "10px",
  },

  shadow: {
    default:
      "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  },

  logo: "logo.png",
};

export default DarkTheme;

import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledInput } from "../../../styles/InputStyles";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { useTheme } from "styled-components";
import EntityTranslationsContainer from "../../../components/entityTranslationsContainer/EntityTranslationsContainer";
import TinyEditor from "../../../components/tinyEditor/TinyEditor";
import Select from "../../../components/select/main/Select";
import { StyledFlexItem, StyledFlexRow } from "../../../styles/LayoutStyles";

function HelpTextPageView({
  helpTextData,
  translationsController,
  descriptionController,
  onNameChange,
  onPathChange,
  onShowInSubpathsChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader groups="help_text_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"help_text_page_submit"}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            error={EVAApiHelper.getResponseError("name", submitError)}
            required
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.name")}
              value={helpTextData.name}
              onChange={onNameChange}
            />
          </InputWrapper>

          <StyledFlexRow style={{ marginTop: theme.spacing.normal }}>
            <StyledFlexItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.path")}
                error={EVAApiHelper.getResponseError("path", submitError)}
                required
              >
                <StyledInput
                  type="text"
                  placeholder={translate(
                    "eva.main.validation.placeholders.path"
                  )}
                  value={helpTextData.path}
                  onChange={onPathChange}
                />
              </InputWrapper>
            </StyledFlexItem>

            <InputWrapper
              label={translate(
                "eva.main.validation.attributes.show_in_subpaths"
              )}
              error={EVAApiHelper.getResponseError(
                "show_in_subpaths",
                submitError
              )}
              required
            >
              <Select
                value={helpTextData.show_in_subpaths}
                options={[
                  {
                    value: true,
                    label: translate("eva.main.general.yes"),
                  },
                  {
                    value: false,
                    label: translate("eva.main.general.no"),
                  },
                ]}
                onChange={onShowInSubpathsChange}
              />
            </InputWrapper>
          </StyledFlexRow>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader groups="help_text_page_fetch">
        <EntityTranslationsContainer
          controller={translationsController}
          style={{ marginTop: theme.spacing.large }}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.title")}
            error={translationsController.getTranslationError(
              "title",
              submitError
            )}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.title")}
              value={translationsController.getValue("title")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "title"
                )
              }
            />
          </InputWrapper>

          <InputWrapper
            style={{ marginTop: theme.spacing.normal }}
            label={translate("eva.main.validation.attributes.description")}
            error={translationsController.getTranslationError(
              "description",
              submitError
            )}
          >
            <TinyEditor controller={descriptionController} />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>
    </>
  );
}

export default HelpTextPageView;

import { useTheme } from "styled-components";
import Layout from "../../../../components/layout/Layout";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTerminalContext } from "../../../../context/TerminalContext";
import { StyledFlexColumn } from "../../../../styles/LayoutStyles";
import { StartPageConfig } from "../start/StartPage";
import { VisitorTypePageConfig } from "../visitor_type/VisitorTypePage";
import { StyledAltButton, StyledButton } from "../../../../styles/ButtonStyles";

function SignInPageView({ setPageConfig }) {
  const theme = useTheme();
  const { terminal } = useTerminalContext();
  const { translateEntity, translate } = useLanguageContext();

  return (
    <Layout
      layoutData={terminal?.layout}
      content={
        <StyledFlexColumn style={{ gap: theme.spacing.large }}>
          <StyledFlexColumn>
            {terminal?.visitor_types
              ?.sort((a, b) => a.order - b.order)
              ?.map((visitorType) => (
                <StyledButton
                  key={visitorType.id}
                  onClick={() =>
                    setPageConfig(
                      VisitorTypePageConfig.setData({
                        visitorTypeId: visitorType.id,
                      })
                    )
                  }
                >
                  {translateEntity(visitorType, "title")}
                </StyledButton>
              ))}
          </StyledFlexColumn>

          <StyledAltButton onClick={() => setPageConfig(StartPageConfig)}>
            {translate("eva.frontoffice.general.back")}
          </StyledAltButton>
        </StyledFlexColumn>
      }
    />
  );
}

export default SignInPageView;

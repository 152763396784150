import styled from "styled-components";

export const StyledSubForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
  padding: ${(props) => (props.active ? props.theme.spacing.normal : 0)};
  padding-top: ${(props) => (props.active ? props.theme.spacing.large : 0)};
  border-radius: ${(props) => (props.active ? props.theme.radius.normal : 0)};
  border: 1px solid
    ${(props) => (props.active ? props.theme.colors.primary.lightest : 0)};
  margin-top: ${(props) =>
    props.active ? `calc(${props.theme.spacing.large} * 2)` : 0};
`;

export const StyledSubFormHeader = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.primary.normal};
  padding: ${(props) => props.theme.spacing.small};
  padding-inline: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
  font-weight: bold;
  transform: ${(props) => `translate(${props.theme.spacing.normal}, -50%)`};
  display: ${(props) => (props.active ? "block" : "none")};
`;

export const StyledMessage = styled.div`
  background-color: ${(props) => props.theme.colors.primary.dark};
  border-radius: ${(props) => props.theme.radius.normal};
  padding: ${(props) => props.theme.spacing.normal};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledMessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
    padding-bottom: ${(props) => props.theme.spacing.normal};
    margin-bottom: ${(props) => props.theme.spacing.normal};
  }
`;

export const StyledMessageHeaderLabel = styled.p`
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
`;

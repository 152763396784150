import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import {
  StyledAltButton,
  StyledTextButton,
} from "../../../../../../styles/ButtonStyles";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  StyledMessage,
  StyledMessageHeader,
  StyledMessageHeaderLabel,
  StyledSubForm,
  StyledSubFormHeader,
} from "./FlowPageMessagesFormStyles";
import {
  StyledFlexItem,
  StyledFlexRow,
} from "../../../../../../styles/LayoutStyles";
import CompactError from "../../../../../../components/error/compact/CompactError";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";

function FlowPageMessagesFormView({
  flowData,
  messageTemplates,
  employees,
  asSubForm,
  subFormHeader,
  onCreateMessage,
  onDeleteMessage,
  onReceiverTypeChange,
  onEmployeeChange,
  submitError,
}) {
  const { translate } = useLanguageContext();

  return (
    <StyledSubForm active={asSubForm}>
      <StyledSubFormHeader active={asSubForm}>
        {subFormHeader ?? translate("eva.main.entities.messages")}
      </StyledSubFormHeader>

      {/* Messages */}
      {flowData.steps[flowData.selectedStep].messages?.map((message, index) => (
        <StyledMessage key={index}>
          <StyledMessageHeader>
            <StyledMessageHeaderLabel>
              {
                messageTemplates.find(
                  (mt) => mt.id === message.message_template_id
                )?.name
              }

              <CompactError
                error={EVAApiHelper.getResponseErrorsLike(
                  `steps.${flowData.selectedStep}.messages.${index}`,
                  submitError,
                  translate
                )}
              />
            </StyledMessageHeaderLabel>

            {/* Delete message button */}
            <StyledTextButton
              onClick={() => onDeleteMessage(index)}
              title={translate("eva.main.entities.states.delete", [
                {
                  key: "entities",
                  value: translate("eva.main.entities.message"),
                },
              ])}
            >
              <FontAwesomeIcon icon={faTrash} />
            </StyledTextButton>
          </StyledMessageHeader>

          <StyledFlexRow>
            <StyledFlexItem>
              {/* Receiver type */}
              <Select
                value={message.receiver_type}
                options={[
                  {
                    value: "employee",
                    label: translate("eva.main.entities.employee"),
                  },
                  {
                    value: "employee_extern",
                    label: `${translate(
                      "eva.main.entities.employee"
                    )} (${translate("eva.main.general.extern")})`,
                  },
                  {
                    value: "visitor",
                    label: translate("eva.main.entities.visitor"),
                  },
                ]}
                onChange={(val) => onReceiverTypeChange(index, val)}
              />
            </StyledFlexItem>

            {/* Employee */}
            {message.receiver_type === "employee_extern" && (
              <Select
                value={message.employee_id}
                options={employees.map((emp) => ({
                  value: emp.id,
                  label: emp.name,
                }))}
                onChange={(val) => onEmployeeChange(index, val)}
              />
            )}
          </StyledFlexRow>
        </StyledMessage>
      ))}

      {/* Create message button */}
      <Select
        as={StyledAltButton}
        valueVisual={(val) => <FontAwesomeIcon icon={faPlus} />}
        hideCaret
        title={translate("eva.main.entities.states.create", [
          { key: "entities", value: translate("eva.main.entities.message") },
        ])}
        options={messageTemplates.map((mt) => ({
          value: mt.id,
          label: mt.name,
        }))}
        onChange={onCreateMessage}
      />
    </StyledSubForm>
  );
}

export default FlowPageMessagesFormView;

import { useLanguageContext } from "../../context/LanguageContext";
import EVAApiHelper from "../../helpers/EVAApiHelper";
import { StyledInput } from "../../styles/InputStyles";
import {
  StyledFlexColumn,
  StyledFlexItem,
  StyledFlexRow,
} from "../../styles/LayoutStyles";
import InputWrapper from "../input/wrapper/InputWrapper";
import Select from "../select/main/Select";

function FlowFieldsView({ step, data, errors, onFieldChange, frontoffice }) {
  const { translate, translateEntity } = useLanguageContext();

  function getInputComponent(stepField) {
    const field = stepField.field;
    const fieldValue = data[`field_${field.id}`];

    switch (field.type) {
      case "text":
      case "date":
      case "time":
        return (
          <StyledInput
            value={fieldValue ?? ""}
            onChange={(e) => onFieldChange(stepField, e.target.value)}
            type={field.type}
            placeholder={translateEntity(field, "label")}
          />
        );
      case "select":
      case "boolean":
        return (
          <Select
            value={fieldValue}
            nullable={true}
            onChange={(value) => onFieldChange(stepField, value)}
            options={
              field.type === "boolean"
                ? [
                    { value: "1", label: translate("eva.main.general.yes") },
                    { value: "0", label: translate("eva.main.general.no") },
                  ]
                : field.options.map((option) => ({
                    value: option.id,
                    label: translateEntity(option, "label"),
                  }))
            }
          />
        );
      default:
        return null;
    }
  }

  return (
    <StyledFlexColumn>
      {step?.fields?.map((row, index) => (
        <StyledFlexRow key={index}>
          {row.map((stepField) => (
            <StyledFlexItem key={stepField.id}>
              <InputWrapper
                label={translateEntity(stepField.field, "label")}
                required={
                  frontoffice
                    ? stepField.required_terminal
                    : stepField.required_backoffice
                }
                error={EVAApiHelper.getResponseError(
                  `field_${stepField.field.id}`,
                  errors
                )}
              >
                {getInputComponent(stepField)}
              </InputWrapper>
            </StyledFlexItem>
          ))}
        </StyledFlexRow>
      ))}
    </StyledFlexColumn>
  );
}

export default FlowFieldsView;

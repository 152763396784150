import { createContext, useContext, useState } from "react";
import useEvaApi, { EvaApiCall } from "../hooks/useEvaApi";
import { useAppContext } from "./AppContext";
import useCustomEffect from "../hooks/useCustomEffect";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";

const CalamityContext = createContext();

export function CalamityProvider({ children }) {
  const { contexts } = useAppContext();
  const callEvaApi = useEvaApi();
  const [calamities, setCalamities] = useState([]);

  /**
   * UseEffect for fetching the calamities when the user changes
   */
  useCustomEffect(() => {
    fetchCalamities();
  }, [contexts.authContext?.auth?.user]);

  /**
   * This callback will fetch the calamities from the EVA API
   */
  async function fetchCalamities() {
    if (
      !contexts.authContext?.auth?.user ||
      contexts.authContext?.auth.user?.company?.type !== "default"
    ) {
      setCalamities([]);
      return;
    }

    await callEvaApi(
      new EvaApiCall("calamities")
        .setLoadingGroup("APP")
        .setBlockAppRender(true)
        .setOnSuccess((response) => {
          setCalamities(response.data.data.records);
        })
        .setAlertError(false)
        .setAlertSuccess(false)
    );
  }

  return useContextDefiner(
    new ContextDefinition("calamityContext", CalamityContext)
      .setData({ calamities, fetchCalamities })
      .setChildren(children)
  );
}

export function useCalamityContext() {
  return useContext(CalamityContext);
}

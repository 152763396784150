import { faPaintBrush } from "@fortawesome/free-solid-svg-icons";
import { useThemeContext } from "../../../../context/ThemeContext";
import IconSelect from "../../main/icon/IconSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../../context/LanguageContext";

function ThemeSelectView() {
  const { translate } = useLanguageContext();
  const themeContext = useThemeContext();
  return (
    <IconSelect
      value={themeContext.theme}
      onChange={themeContext.onThemeChange}
      icon={() => <FontAwesomeIcon icon={faPaintBrush} />}
      title={translate("eva.main.general.theme")}
      options={themeContext.availableThemes.map((t) => ({
        value: t.id,
        label: translate(`eva.main.themes.${t.id}`),
      }))}
    />
  );
}

export default ThemeSelectView;

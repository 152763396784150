class DashboardNavGroupConfig {
  constructor() {
    this.uniqueKey = null;
    this.label = null;
    this.buttonConfigs = [];
  }

  /**
   * @param {string} uniqueKey
   * @returns {DashboardNavGroupConfig}
   */
  setUniqueKey(uniqueKey) {
    this.uniqueKey = uniqueKey;
    return this;
  }

  /**
   * @param {string} label
   * @returns {DashboardNavGroupConfig}
   */
  setLabel(label) {
    this.label = label;
    return this;
  }

  /**
   * @param {DashboardNavButtonConfig[]} buttonConfigs
   * @returns {DashboardNavGroupConfig}
   */
  setButtonConfigs(buttonConfigs) {
    this.buttonConfigs = buttonConfigs;
    return this;
  }

  /**
   * This method will return the disabled state of the group
   * @returns {boolean}
   */
  getDisabled() {
    let disabled = true;
    this.buttonConfigs.forEach((buttonConfig) => {
      if (!buttonConfig.getDisabled()) {
        disabled = false;
      }
    });
    return disabled;
  }

  /**
   * This method will return the hidden state of the group
   * @returns {boolean}
   */
  getHidden() {
    let hidden = true;
    this.buttonConfigs.forEach((buttonConfig) => {
      if (!buttonConfig.hidden) {
        hidden = false;
      }
    });
    return hidden;
  }
}

export default DashboardNavGroupConfig;

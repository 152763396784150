import FormContainer from "../../../components/formContainer/FormContainer";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";

import { useLanguageContext } from "../../../context/LanguageContext";
import { useTheme } from "styled-components";
import {
  StyledFlexColumn,
  StyledFlexItem,
  StyledFlexRow,
} from "../../../styles/LayoutStyles";
import { StyledInput } from "../../../styles/InputStyles";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import Select from "../../../components/select/main/Select";
import EntityTranslationsContainer from "../../../components/entityTranslationsContainer/EntityTranslationsContainer";
import FileInput from "../../../components/input/file/FileInput";

function FilePageView({
  file,
  fileData,
  translationsController,
  accept,
  onTypeChange,
  onFileChange,
  onNameChange,
  onUseTranslationsChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader groups="file_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"file_page_save"}
        >
          <StyledFlexColumn>
            <StyledFlexRow>
              <StyledFlexItem>
                {/* Name Input */}
                <InputWrapper
                  label={translate("eva.main.validation.attributes.name")}
                  error={EVAApiHelper.getResponseError("name", submitError)}
                  required
                >
                  <StyledInput
                    type="text"
                    placeholder={translate(
                      "eva.main.validation.attributes.name"
                    )}
                    onChange={onNameChange}
                    value={fileData.name}
                  />
                </InputWrapper>
              </StyledFlexItem>

              {/* use translations */}
              <InputWrapper
                label={translate(
                  "eva.main.validation.attributes.use_translations"
                )}
                error={EVAApiHelper.getResponseError(
                  "use_translations",
                  submitError
                )}
                required
              >
                <Select
                  value={fileData.use_translations}
                  options={[
                    { value: true, label: translate("eva.main.general.yes") },
                    { value: false, label: translate("eva.main.general.no") },
                  ]}
                  onChange={onUseTranslationsChange}
                />
              </InputWrapper>
            </StyledFlexRow>

            {/* Type input */}
            <InputWrapper
              label={translate("eva.main.validation.attributes.type")}
              required
              error={EVAApiHelper.getResponseError("type", submitError)}
            >
              <Select
                disabled={file}
                value={fileData.type}
                options={[
                  {
                    value: "attachment",
                    label: translate("eva.main.file_types.attachment"),
                  },
                  {
                    value: "image",
                    label: translate("eva.main.file_types.image"),
                  },
                  {
                    value: "houserule",
                    label: translate("eva.main.file_types.houserule"),
                  },
                ]}
                onChange={onTypeChange}
              />
            </InputWrapper>

            {/* File Input */}
            {!fileData.use_translations && (
              <InputWrapper
                error={EVAApiHelper.getResponseError("file", submitError)}
                label={translate("eva.main.validation.attributes.file")}
                required
              >
                <FileInput
                  values={fileData.file}
                  onChange={onFileChange}
                  accept={accept}
                />
              </InputWrapper>
            )}
          </StyledFlexColumn>
        </FormContainer>
      </OverlayLoader>

      {fileData.use_translations && (
        <OverlayLoader groups="file_page_fetch">
          <EntityTranslationsContainer
            controller={translationsController}
            style={{ marginTop: theme.spacing.large }}
          >
            <StyledFlexColumn>
              {/* Name translation */}
              <InputWrapper
                label={translate("eva.main.validation.attributes.name")}
                error={translationsController.getTranslationError(
                  "name",
                  submitError
                )}
              >
                <StyledInput
                  type="text"
                  value={translationsController.getValue("name")}
                  placeholder={translate("eva.main.validation.attributes.name")}
                  onChange={(e) =>
                    translationsController.onTranslationChange(
                      e.target.value,
                      "name"
                    )
                  }
                />
              </InputWrapper>

              {/* File translation */}
              <InputWrapper
                label={translate("eva.main.validation.attributes.file")}
                error={translationsController.getTranslationError(
                  "file",
                  submitError
                )}
              >
                <FileInput
                  values={translationsController.getValue("file")}
                  onChange={(value) =>
                    translationsController.onTranslationChange(value, "file")
                  }
                  accept={accept}
                />
              </InputWrapper>
            </StyledFlexColumn>
          </EntityTranslationsContainer>
        </OverlayLoader>
      )}
    </>
  );
}

export default FilePageView;

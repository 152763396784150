import styled from "styled-components";

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing.normal};

  & > * {
    ${(props) => (props.$preventChildStyling ? "" : "max-width: 100%;")}
  }
`;

export const StyledFlexItem = styled.div`
  flex: 1;
`;

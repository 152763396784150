import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";
import {
  StyledFlexItem,
  StyledFlexRow,
} from "../../../../../../styles/LayoutStyles";
import FlowPageMessagesForm from "../messages/FlowPageMessagesForm";

function FlowPageQuestionnaireFormView({
  flowData,
  setFlowData,
  questionnaires,
  messageTemplates,
  employees,
  onQuestionnaireChange,
  onFailChange,
  onFailStepChange,
  submitError,
}) {
  const { translate } = useLanguageContext();

  return (
    <>
      <InputWrapper
        label={translate("eva.main.entities.questionnaire")}
        error={EVAApiHelper.getResponseError(
          `steps.${flowData.selectedStep}.questionnaire_id`,
          submitError
        )}
        compactError={true}
        required
      >
        <Select
          value={flowData.steps[flowData.selectedStep].questionnaire_id}
          options={questionnaires.map((questionnaire) => ({
            value: questionnaire.id,
            label: questionnaire.name,
          }))}
          onChange={onQuestionnaireChange}
        />
      </InputWrapper>

      <InputWrapper
        label={translate("eva.main.validation.attributes.on_fail")}
        error={EVAApiHelper.getResponseError(
          `steps.${flowData.selectedStep}.on_fail`,
          submitError
        )}
        compactError={true}
      >
        <StyledFlexRow>
          <StyledFlexItem>
            <Select
              value={flowData.steps[flowData.selectedStep].on_fail ?? null}
              options={[
                {
                  value: null,
                  label: translate("eva.main.general.continue"),
                },
                {
                  value: "retry",
                  label: translate("eva.main.general.retry"),
                },
                {
                  value: "go_to",
                  label: translate("eva.main.general.go_to"),
                },
                {
                  value: "stop",
                  label: translate("eva.main.general.stop"),
                },
              ]}
              onChange={onFailChange}
            />
          </StyledFlexItem>

          {flowData.steps[flowData.selectedStep].on_fail === "go_to" && (
            <Select
              value={flowData.steps[flowData.selectedStep].on_fail_step}
              options={flowData.steps.map((step, index) => ({
                value: index,
                label: `${index + 1}. ${translate(
                  `eva.main.entities.${step.type}`
                )}`,
                disabled: index === flowData.selectedStep,
              }))}
              onChange={onFailStepChange}
            />
          )}
        </StyledFlexRow>
      </InputWrapper>

      {/* Messages */}
      <FlowPageMessagesForm
        flowData={flowData}
        setFlowData={setFlowData}
        messageTemplates={messageTemplates}
        employees={employees}
        asSubForm={true}
        subFormHeader={`${translate("eva.main.entities.messages")} (${translate(
          "eva.main.validation.attributes.on_fail"
        )})`}
        submitError={submitError}
      />
    </>
  );
}

export default FlowPageQuestionnaireFormView;

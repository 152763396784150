import { createGlobalStyle } from "styled-components";

const TinyEditorStyles = createGlobalStyle`
  .tox-tinymce {
    border: 1px solid ${(props) =>
      props.theme.colors.primary.darkest} !important;
    border-radius: ${(props) => props.theme.radius.normal} !important;
    font-size: ${(props) => props.theme.font.size.normal} !important;
  }
  .tox-editor-header {
    background-color: ${(props) =>
      props.theme.colors.primary.darkest} !important;
  }
  .tox-toolbar-overlord {
    background-color: ${(props) =>
      props.theme.colors.primary.darkest} !important;
  }
  .tox-toolbar__primary {
    background-color: ${(props) =>
      props.theme.colors.primary.darkest} !important;
  }
  .tox-toolbar__overflow {
    background-color: ${(props) =>
      props.theme.colors.primary.darkest} !important;
  }
  .tox-toolbar__group {
    padding-inline: ${(props) => props.theme.spacing.normal} !important;
  }
  .tox-tbtn svg {
    fill: ${(props) => props.theme.colors.on.primary} !important;
  }
  .tox-tbtn {
    width: fit-content !important;
    background-color: transparent !important;
  }
  .tox-tbtn:hover, .tox-tbtn:focus, .tox-tbtn--enabled, .tox-tbtn__icon-wrap {
    background-color: transparent !important;
  }
  .tox-tbtn:focus svg {
    fill: ${(props) => props.theme.colors.accent.normal} !important;
  }
  .tox-tbtn:hover svg {
    fill: ${(props) => props.theme.colors.accent.dark} !important;
  }
  .tox-tbtn--enabled svg {
    fill: ${(props) => props.theme.colors.accent.normal} !important;
  }
  .tox-tbtn--disabled svg {
    fill: ${(props) => props.theme.colors.primary.dark} !important;
  }
  .tox-tbtn--disabled:hover svg {
    fill: ${(props) => props.theme.colors.primary.dark} !important;
  }
  .tox-tbtn--select {
    background-color: ${(props) =>
      props.theme.colors.primary.darkest} !important;
    color: ${(props) => props.theme.colors.on.primary} !important;
    border: 1px solid ${(props) =>
      props.theme.colors.primary.lightest} !important;
    font-size: ${(props) => props.theme.font.size.normal} !important;
    border-radius: ${(props) => props.theme.radius.normal} !important;
    
    &:hover {
      border-color: ${(props) => props.theme.colors.accent.dark} !important;
    }
  }
  .tox-tbtn--select:hover .tox-tbtn__select-chevron svg {
    fill: ${(props) => props.theme.colors.on.primary} !important
  }
  .tox-tbtn__select-label {
    color: ${(props) => props.theme.colors.on.primary} !important
  }
  .tox-tbtn__select-chevron svg {
    fill: ${(props) => props.theme.colors.on.primary} !important
  }
  .tox-edit-area__iframe {
    background-color: ${(props) =>
      props.theme.colors.primary.lightest} !important;
  }
  .mce-content-body * {
    margin: 0 !important;
  }
  .tox-statusbar {
    background-color: ${(props) =>
      props.theme.colors.primary.darkest} !important;
    border: 0px solid transparent !important;
  }
  .tox-statusbar__path-item {
    color: ${(props) => props.theme.colors.primary.normal} !important;
  }
  .tox-statusbar__path-divider {
    color: ${(props) => props.theme.colors.primary.normal} !important;
  }
  .tox-statusbar__branding svg {
    fill: ${(props) => props.theme.colors.primary.normal} !important;
  }
  .tox-dialog-wrap__backdrop {
    background-color: rgba(0, 0, 0, 0.90) !important;
    backdrop-filter: blur(4px);
  }
  .tox-dialog {
    margin: ${(props) => props.theme.spacing.large} !important;
    background-color: ${(props) =>
      props.theme.colors.primary.normal} !important;
    border: 1px solid ${(props) =>
      props.theme.colors.primary.lightest} !important;
  }
  .tox-dialog__header {
    background-color: ${(props) =>
      props.theme.colors.primary.darkest} !important;
    padding: ${(props) => props.theme.spacing.normal} !important;
  }
  .tox-dialog__title {
    color: ${(props) => props.theme.colors.on.primary} !important;
    font-weight: bold !important;
    font-size: ${(props) => props.theme.font.size.lg} !important;
  }
  .tox-button {
    border-radius: ${(props) => props.theme.radius.normal} !important;
    background-color: ${(props) => props.theme.colors.accent.normal} !important;
    border: 0 !important;
    color: ${(props) => props.theme.colors.on.accent} !important;
    padding: ${(props) => props.theme.spacing.normal} !important;
    font-size: ${(props) => props.theme.font.size.normal} !important;
    font-weight: normal !important;

    &:hover {
      background-color: ${(props) => props.theme.colors.accent.dark} !important;
      color: ${(props) => props.theme.colors.on.accent} !important;
    }
  }
  .tox-button--secondary {
    background-color: ${(props) =>
      props.theme.colors.primary.lightest} !important;
    color: ${(props) => props.theme.colors.on.primary} !important;
  }
  .tox-button--icon {
    background-color: ${(props) =>
      props.theme.colors.primary.lightest} !important;
    color: ${(props) => props.theme.colors.on.primary} !important;
    padding: 0 !important;
    width: ${(props) => props.theme.container.size.normal} !important;
    height: ${(props) => props.theme.container.size.normal} !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .tox-dialog__body-content {
    padding: ${(props) => props.theme.spacing.normal} !important;
  }
  .tox-dialog .tox-label {
    color: ${(props) => props.theme.colors.on.primary} !important;
    font-size: ${(props) => props.theme.font.size.normal} !important;
  }
  .tox-form {
    gap: ${(props) => props.theme.spacing.normal} !important;
  }
  .tox-form__group {
    margin: 0 !important;
  }
  .tox-form__controls-h-stack {
    gap: ${(props) => props.theme.spacing.normal} !important;
  }
  .tox-textfield {
    background-color: ${(props) =>
      props.theme.colors.primary.lightest} !important;
    border: 1px solid transparent !important;
    padding: ${(props) => props.theme.spacing.normal} !important;
    border-radius: ${(props) => props.theme.radius.normal} !important;
    color: ${(props) => props.theme.colors.on.primary} !important;
    outline: none !important;
    font-size: ${(props) => props.theme.font.size.normal} !important;

    ::placeholder {
      color: ${(props) => props.theme.colors.on.primary} !important;
      opacity: 0.3 !important;
    }

    &:hover {
      border-color: ${(props) => props.theme.colors.accent.dark} !important;
    }

    &:focus {
      border-color: ${(props) => props.theme.colors.accent.normal} !important;
    }
  }
  .tox-listbox--select {
    background-color: ${(props) =>
      props.theme.colors.primary.lightest} !important;
    color: ${(props) => props.theme.colors.on.primary} !important;
    border: 1px solid transparent !important;
    padding: ${(props) => props.theme.spacing.normal} !important;
    font-size: ${(props) => props.theme.font.size.normal} !important;
    border-radius: ${(props) => props.theme.radius.normal} !important;
    
    &:hover {
      border-color: ${(props) => props.theme.colors.accent.dark} !important;
    }
  }
  .tox-listbox__select-chevron svg {
    fill: ${(props) => props.theme.colors.on.primary} !important;
  }
  .tox-dialog__footer {
    background-color: ${(props) =>
      props.theme.colors.primary.normal} !important;
    padding: ${(props) => props.theme.spacing.normal} !important;
  }
  .tox-collection {
    background-color: ${(props) =>
      props.theme.colors.primary.darkest} !important;
    border: 1px solid ${(props) =>
      props.theme.colors.primary.lightest} !important;
    border-radius: ${(props) => props.theme.radius.normal} !important;
  }
  .tox-collection__item {
    color: ${(props) => props.theme.colors.on.primary} !important;
    border-radius: ${(props) => props.theme.radius.normal} !important;
    padding-block: ${(props) => props.theme.spacing.small} !important;
    padding-inline: ${(props) => props.theme.spacing.normal} !important;
  }
  .tox-collection__item--active {
    background-color: ${(props) => props.theme.colors.primary.dark} !important;
  }
  .tox-collection__item--enabled {
    background-color: ${(props) => props.theme.colors.accent.normal} !important;
  }
  .tox-collection__item-label {
    margin-left: 0 !important;
  }
  .tox-collection__item-label code {
    background-color: transparent !important;
  }
  .tox-collection__item-caret {
    margin-left: ${(props) => props.theme.spacing.small} !important;
  }
  .tox-collection__item-caret svg {
    fill: ${(props) => props.theme.colors.on.primary} !important;
  }
  .tox-notifications-container {
    display: none !important;
    opacity: 0 !important;
    pointer-events: none !important;
  }
`;

export default TinyEditorStyles;

import EVAApi from "../apis/EVAApi";
import EVAApiHelper from "../helpers/EVAApiHelper";
import FileHelper from "../helpers/FileHelper";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

function useEvaApi() {
  const navigate = useNavigate();
  const { contexts, enableAppRenderBlock, disableAppRenderBlock } =
    useAppContext();

  /**
   * This method will call the API with the given configuration
   * @param {EvaApiCall} config
   */
  async function callEvaApi(config) {
    // Set the error state
    if (config.errorState) {
      config.errorState(null);
    }

    // Block the app render
    const blockTag = config.blockAppRender ? enableAppRenderBlock() : null;

    // Enable the loader
    const loadTag = config.loadingGroup
      ? contexts.loadContext?.enableLoader(config.loadingGroup)
      : null;

    try {
      // Make the API call
      const response = await EVAApi.request({
        url: config.url,
        method: config.method === "PUT" ? "POST" : config.method,
        data: EVAApiHelper.createFormData(
          config.data,
          config.method === "PUT",
          config.ignore
        ),
        params: config.params,
        headers: config.headers,
        responseType: config.downloadAs ? "blob" : "json",
      });

      if (response.status === 200) {
        // Show the success alert
        if (
          config.alertSuccess ||
          (config.alertSuccess === null && config.method !== "GET")
        ) {
          contexts.alertContext?.showAlert(response.data.message, "success");
        }

        // Download the file
        if (config.downloadAs) {
          FileHelper.download(response.data, config.downloadAs);
        }

        // Redirect on success
        if (config.redirectOnSuccess) {
          if (typeof config.redirectOnSuccess === "string") {
            navigate(config.redirectOnSuccess);
          } else {
            contexts.routeContext?.back();
          }
        }

        //Call the success callback
        if (config.onSuccess) {
          config.onSuccess(response);
        }
      }
    } catch (error) {
      // Set the error state
      if (config.errorState) {
        config.errorState(error);
      }

      // Show the error alert
      if (
        config.alertError ||
        (config.alertError === null && config.errorState === null)
      ) {
        contexts.alertContext?.showAlert(
          EVAApiHelper.getResponseError("message", error, true),
          "error"
        );
      }

      // Redirect on error
      if (config.redirectOnError) {
        if (typeof config.redirectOnError === "string") {
          navigate(config.redirectOnError);
        } else {
          contexts.routeContext?.back();
        }
      }

      //Call the error callback
      if (config.onError) {
        config.onError(error);
      }
    } finally {
      // Disable the loader
      if (loadTag) {
        contexts.loadContext?.disableLoader(loadTag);
      }

      // Disable the app render block
      if (blockTag) {
        disableAppRenderBlock(blockTag);
      }
    }
  }

  return callEvaApi;
}

export default useEvaApi;

export class EvaApiCall {
  constructor(url) {
    this.url = url;
    this.method = "GET";
    this.data = null;
    this.ignore = [];
    this.params = null;
    this.headers = null;

    this.loadingGroup = null;
    this.blockAppRender = false;

    this.onSuccess = null;
    this.alertSuccess = null;
    this.redirectOnSuccess = false;

    this.onError = null;
    this.alertError = null;
    this.redirectOnError = false;
    this.errorState = null;

    this.downloadAs = null;
  }

  /**
   * @param {string} method GET, POST, PUT, DELETE
   * @returns {EvaApiCall}
   */
  setMethod(method) {
    this.method = method.toUpperCase();
    return this;
  }

  /**
   * @param {object} data
   * @param {string[]} ignore
   * @returns {EvaApiCall}
   */
  setData(data, ignore = []) {
    this.data = data;
    this.ignore = ignore;
    return this;
  }

  /**
   * @param {object} params
   * @returns {EvaApiCall}
   */
  setParams(params) {
    this.params = params;
    return this;
  }

  /**
   * @param {object} headers
   * @returns {EvaApiCall}
   */
  setHeaders(headers) {
    this.headers = headers;
    return this;
  }

  /**
   * @param {string} loadingGroup
   * @returns {EvaApiCall}
   */
  setLoadingGroup(loadingGroup) {
    this.loadingGroup = loadingGroup;
    return this;
  }

  /**
   * @param {boolean} blockAppRender
   * @returns {ApiCall}
   */
  setBlockAppRender(blockAppRender) {
    this.blockAppRender = blockAppRender;
    return this;
  }

  /**
   * @param {bool} alertError
   * @returns {EvaApiCall}
   */
  setAlertError(alertError) {
    this.alertError = alertError;
    return this;
  }

  /**
   * @param {bool} alertSuccess
   * @returns {EvaApiCall}
   */
  setAlertSuccess(alertSuccess) {
    this.alertSuccess = alertSuccess;
    return this;
  }

  /**
   * @param {function(response)} onSuccess
   * @returns {EvaApiCall}
   */
  setOnSuccess(onSuccess) {
    this.onSuccess = onSuccess;
    return this;
  }

  /**
   * @param {function(error)} onError
   * @returns {EvaApiCall}
   */
  setOnError(onError) {
    this.onError = onError;
    return this;
  }

  /**
   * @param {bool} redirectOnError
   * @returns {EvaApiCall}
   */
  setRedirectOnError(redirectOnError) {
    this.redirectOnError = redirectOnError;
    return this;
  }

  /**
   * @param {bool} redirectOnSuccess
   * @returns {EvaApiCall}
   */
  setRedirectOnSuccess(redirectOnSuccess) {
    this.redirectOnSuccess = redirectOnSuccess;
    return this;
  }

  /**
   * @param {function(any)} errorState the function to set the error state
   * @returns {EvaApiCall}
   */
  setErrorState(errorState) {
    this.errorState = errorState;
    return this;
  }

  /**
   * @param {string} downloadAs the file name to download as
   * @returns {EvaApiCall}
   */
  setDownloadAs(downloadAs) {
    this.downloadAs = downloadAs;
    return this;
  }
}

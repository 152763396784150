import styled from "styled-components";

export const StyledHierarchy = styled.div`
  padding: ${(props) => props.theme.spacing.normal};
  padding-bottom: ${(props) => props.theme.spacing.large};
  overflow-y: auto;
`;

export const StyledHierarchyItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing.normal};
`;

export const StyledHierarchyItemLine = styled.div`
  height: 1px;
  min-width: ${(props) => props.theme.spacing.large};
  background-color: ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledHierarchyItem = styled.div`
  background-color: ${(props) => props.theme.colors.primary.lightest};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
  border: 1px solid
    ${(props) =>
      props.hovered ? props.theme.colors.accent.dark : "transparent"};
`;

export const StyledHierarchyItemActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledHierarchyItemChildren = styled.div`
  margin-left: ${(props) => props.nested && props.theme.spacing.large};
  border-left: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

import styled from "styled-components";
import {
  StyledAltButton,
  StyledIconButton,
  StyledTextButton,
} from "../../../styles/ButtonStyles";

export const NAV_FOLD_ON_WIDTH = 768;
export const NAV_HEIGHT = 80;
export const NAV_WIDTH_FOLDED = 60;
export const NAV_WIDTH = 200;

export const StyledAside = styled.aside`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  border-right: 1px solid ${(props) => props.theme.colors.primary.lightest};
  width: ${(props) =>
    props.$folded ? `${NAV_WIDTH_FOLDED}px` : `${NAV_WIDTH}px`};

  @media (max-width: ${NAV_FOLD_ON_WIDTH}px) {
    width: ${NAV_WIDTH_FOLDED}px;
  }
`;

export const StyledLogoWrapper = styled.div`
  height: ${NAV_HEIGHT}px;
  padding: ${(props) => props.theme.spacing.large};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledLogo = styled.img`
  max-height: 100%;
  margin: 0px auto 0px 0px;
  border-radius: 0px;
  display: ${(props) => (props.$hide ? "none" : "block")};

  @media (max-width: ${NAV_FOLD_ON_WIDTH}px) {
    display: none;
  }
`;

export const StyledNavActions = styled.div`
  position: absolute;
  right: 0px;
  transform: translateX(50%);
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.small};
  align-items: center;
`;

export const StyledNavAction = styled(StyledIconButton)`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: transparent;
    color: ${(props) => props.theme.colors.on.accent};
  }

  @media (max-width: ${NAV_FOLD_ON_WIDTH}px) {
    display: ${(props) => (props.$hideOnFoldWidth ? "none" : "flex")};
  }
`;

export const StyledNavWrapper = styled.div`
  height: ${(props) => `calc(100vh - ${NAV_HEIGHT}px)`};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.large};
  padding: ${(props) =>
    `${props.theme.spacing.large} 0px ${props.theme.spacing.large} ${props.theme.spacing.large}`};
`;

export const StyledNavGroupButton = styled(StyledTextButton)`
  display: ${(props) => (props.$folded ? "none" : "flex")};
  flex-direction: row;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
  color: ${(props) => props.theme.colors.primary.lightest};

  @media (max-width: ${NAV_FOLD_ON_WIDTH}px) {
    display: none;
  }
`;

export const StyledNavGroup = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${(props) =>
    `calc(100% - ${props.theme.spacing.large} - ${props.theme.spacing.normal})`};
`;

export const StyledNavButton = styled(StyledAltButton)`
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
  width: 100%;
  text-align: left;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: ${(props) =>
    props.$active
      ? props.theme.colors.accent.normal
      : props.theme.colors.primary.lightest};
  color: ${(props) =>
    props.$active
      ? props.theme.colors.on.accent
      : props.theme.colors.on.primary};
`;

export const StyledNavButtonLabel = styled.label`
  display: ${(props) => (props.$folded ? "none" : "block")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  pointer-events: none;
`;

export const StyledFullNavWrapper = styled.div`
  position: fixed;
  top: ${NAV_HEIGHT}px;
  left: ${(props) =>
    props.$folded ? `${NAV_WIDTH_FOLDED}px` : `${NAV_WIDTH}px`};
  width: ${(props) =>
    `calc(100% - ${props.$folded ? NAV_WIDTH_FOLDED : NAV_WIDTH}px)`};
  height: calc(100vh - ${NAV_HEIGHT}px);
  background-color: rgba(0, 0, 0, 0.66);
  backdrop-filter: blur(3px);
  z-index: 20;

  @media (max-width: ${NAV_FOLD_ON_WIDTH}px) {
    left: ${NAV_WIDTH_FOLDED}px;
    width: calc(100% - ${NAV_WIDTH_FOLDED}px);
  }
`;

export const StyledFullNav = styled.div`
  width: 100%;
  max-height: 100%;
  background-color: ${(props) => props.theme.colors.primary.darkest};
  overflow-y: auto;
  padding: ${(props) => props.theme.spacing.large};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: ${(props) => props.theme.spacing.large};
  flex-wrap: wrap;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledFullNavGroup = styled.p`
  font-weight: bold;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  padding-bottom: ${(props) => props.theme.spacing.small};
  margin-bottom: ${(props) => props.theme.spacing.small};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const StyledFullNavButton = styled(StyledTextButton)`
  display: block;
  color: ${(props) =>
    props.$active
      ? props.theme.colors.accent.normal
      : props.theme.colors.on.primary};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

import { createContext, useContext, useRef, useState } from "react";
import { LoadProvider } from "./LoadContext";
import { AlertProvider } from "./AlertContext";
import MainFooterAlerts from "../components/alert/main/footer/MainFooterAlerts";
import { ThemeProvider } from "./ThemeContext";
import { RouteProvider } from "./RouteContext";
import { DraggableProvider } from "./DraggableContext";
import { LanguageProvider } from "./LanguageContext";
import { ParameterProvider } from "./ParameterContext";
import { AuthProvider } from "./AuthContext";
import ScreenLoader from "../components/loader/ScreenLoader/ScreenLoader";
import GlobalStyles from "../styles/global/GlobalStyles";
import { HelpTextProvider } from "./HelpTextContext";
import { CalamityProvider } from "./CalamityContext";
import { BrowserRouter } from "react-router-dom";
import useCustomEffect from "../hooks/useCustomEffect";
import { TerminalProvider } from "./TerminalContext";
import RandomizeHelper from "../helpers/RandomizeHelper";

const AppContext = createContext();

export function AppProvider({ children }) {
  const [contexts, setContexts] = useState({});
  const blockAppRenderTags = useRef([]);
  const [blockAppRender, setBlockAppRender] = useState(true);
  const blockedRenderTimeout = useRef(null);

  /**
   * UseEffect for automatically updating the app title
   */
  useCustomEffect(() => {
    updateAppTitle();
  }, [contexts?.authContext?.auth?.user]);

  /**
   * This method will update the app title based on the user context
   */
  function updateAppTitle() {
    let title = "EVA 3";
    if (contexts?.authContext?.auth?.user) {
      if (contexts.authContext.auth.user.company.type === "superadmin") {
        title += " - Beheer";
      } else {
        title += " - " + contexts.authContext.auth.user.company.name;
      }
    }

    document.title = title;
  }

  /**
   * This method will handle the change of a given context
   * @param {string} context
   * @param {any} value
   */
  function onContextChange(context, value) {
    setContexts((prev) => ({ ...prev, [context]: value }));
  }

  /**
   * This method will enable a block on the app render
   * @returns {string} tag
   */
  function enableAppRenderBlock() {
    clearTimeout(blockedRenderTimeout.current);
    setBlockAppRender(true);

    const tag = RandomizeHelper.getUUID();
    blockAppRenderTags.current.push(tag);
    return tag;
  }

  /**
   * This method will disable a block on the app render with the given tag
   * @param {string} tag
   */
  function disableAppRenderBlock(tag) {
    clearTimeout(blockedRenderTimeout.current);

    blockAppRenderTags.current = blockAppRenderTags.current.filter(
      (t) => t !== tag
    );
    if (blockAppRenderTags.current.length === 0) {
      blockedRenderTimeout.current = setTimeout(() => {
        setBlockAppRender(false);
      }, 250);
    }
  }

  return (
    <BrowserRouter>
      <AppContext.Provider
        value={{
          contexts,
          onContextChange,
          enableAppRenderBlock,
          disableAppRenderBlock,
        }}
      >
        <LoadProvider>
          <AlertProvider>
            <ThemeProvider>
              <RouteProvider>
                <DraggableProvider>
                  <LanguageProvider>
                    <ParameterProvider>
                      <AuthProvider>
                        <HelpTextProvider>
                          <CalamityProvider>
                            <TerminalProvider>
                              {!blockAppRender && children}

                              <GlobalStyles />

                              <ScreenLoader groups="APP" />
                              <MainFooterAlerts />
                            </TerminalProvider>
                          </CalamityProvider>
                        </HelpTextProvider>
                      </AuthProvider>
                    </ParameterProvider>
                  </LanguageProvider>
                </DraggableProvider>
              </RouteProvider>
            </ThemeProvider>
          </AlertProvider>
        </LoadProvider>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}

import styled from "styled-components";
import { StyledAltButton } from "../../../styles/ButtonStyles";

export const StyledTinyMessageEditor = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledTagsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary.lightest};
  max-width: 200px;
  min-width: 150px;
  height: 100%;
  border: 1px solid ${(props) => props.theme.colors.primary.darkest};
  overflow: hidden;
  border-radius: ${(props) => props.theme.radius.normal};
  display: flex;
  flex-direction: column;
`;

export const StyledTagsHeader = styled.div`
  padding: ${(props) => props.theme.spacing.normal};
  height: 47.5px;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
  background-color: ${(props) => props.theme.colors.primary.darkest};
`;

export const StyledTagsContent = styled.div`
  flex: 1;
  padding: ${(props) => props.theme.spacing.normal};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledTagsFooter = styled.div`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  height: 25px;
`;

export const StyledTagButton = styled(StyledAltButton)`
  text-align: start;
  background-color: ${(props) => props.theme.colors.primary.light};
  border: 1px solid transparent;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.light};
    border-color: ${(props) => props.theme.colors.accent.dark};
  }
`;

export const StyledTagLabel = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import { StyledFlexItem, StyledFlexRow } from "../../../styles/LayoutStyles";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledInput } from "../../../styles/InputStyles";
import Select from "../../../components/select/main/Select";
import EntityTranslationsContainer from "../../../components/entityTranslationsContainer/EntityTranslationsContainer";
import TinyEditor from "../../../components/tinyEditor/TinyEditor";

function ButtonPageView({
  buttonData,
  buttonMessageController,
  translationsController,
  layouts,
  onNameChange,
  onActiveChange,
  onOrderChange,
  onLayoutIdChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader groups="button_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"button_page_submit"}
        >
          <StyledFlexRow style={{ marginBottom: theme.spacing.normal }}>
            <StyledFlexItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.name")}
                error={EVAApiHelper.getResponseError("name", submitError)}
                required
              >
                <StyledInput
                  type="text"
                  placeholder={translate("eva.main.validation.attributes.name")}
                  value={buttonData.name}
                  onChange={onNameChange}
                />
              </InputWrapper>
            </StyledFlexItem>

            <InputWrapper
              label={translate("eva.main.validation.attributes.order")}
              error={EVAApiHelper.getResponseError("order", submitError)}
            >
              <StyledInput
                type="number"
                placeholder={translate("eva.main.validation.attributes.order")}
                value={buttonData.order}
                onChange={onOrderChange}
              />
            </InputWrapper>
          </StyledFlexRow>

          <InputWrapper
            label={translate("eva.main.validation.attributes.layout_id")}
            error={EVAApiHelper.getResponseError("layout_id", submitError)}
          >
            <Select
              value={buttonData.layout_id}
              nullable={true}
              options={layouts.map((layout) => ({
                value: layout.id,
                label: layout.name,
              }))}
              onChange={onLayoutIdChange}
            />
          </InputWrapper>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader groups="button_page_fetch">
        <EntityTranslationsContainer
          controller={translationsController}
          style={{ marginTop: theme.spacing.large }}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.title")}
            error={translationsController.getTranslationError(
              "title",
              submitError
            )}
            style={{ marginBottom: theme.spacing.normal }}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.title")}
              value={translationsController.getValue("title")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "title"
                )
              }
            />
          </InputWrapper>
          <InputWrapper
            label={translate("eva.main.validation.attributes.message")}
            error={translationsController.getTranslationError(
              "message",
              submitError
            )}
          >
            <TinyEditor controller={buttonMessageController} />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>
    </>
  );
}

export default ButtonPageView;

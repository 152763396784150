import ModalView from "./ModalView";

function Modal({
  title,
  children,
  onClose,
  loadingGroups,
  responsiveWidth = false,
}) {
  return (
    <ModalView
      title={title}
      onClose={onClose}
      loadingGroups={loadingGroups}
      responsiveWidth={responsiveWidth}
    >
      {children}
    </ModalView>
  );
}

export default Modal;

import { useAuthContext } from "../context/AuthContext";

function useAuthMiddleware() {
  const authContext = useAuthContext();

  /**
   * This method will validate the middleware
   * @returns {boolean}
   */
  function validate() {
    return authContext.auth.user !== null;
  }

  /**
   * This method return the redirect instructions on fail
   * @returns {{ to: string, replace: boolean }}
   */
  function redirect() {
    return {
      to: `/login?redirect=${encodeURIComponent(window.location.pathname)}`,
      replace: true,
    };
  }

  //return the middleware
  return { validate, redirect };
}

export default useAuthMiddleware;

import styled from "styled-components";
import { MultiStyle } from "../../../context/ThemeContext";

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  gap: 2.5px;
`;

export const StyledHeader = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledInputRequirement = styled.span`
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.error)
      .setFront(props.theme["settings.error.color.text"] ?? "inherit")
      .get()}
`;

export const StyledInputError = styled.small`
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.error)
      .setFront(props.theme["settings.error.color.text"] ?? "inherit")
      .get()}
  ${(props) =>
    MultiStyle.new(props, "font-size")
      .setFront(`${props.theme["settings.general.font.size"]}px`)
      .get()}
`;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledFlexItem } from "../../../styles/LayoutStyles";
import TinyEditor from "../TinyEditor";
import {
  StyledTagButton,
  StyledTagLabel,
  StyledTagsContainer,
  StyledTagsContent,
  StyledTagsFooter,
  StyledTagsHeader,
  StyledTinyMessageEditor,
} from "./TinyMessageEditorStyles";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../context/LanguageContext";
import OverlayLoader from "../../loader/OverlayLoader/OverlayLoader";

function TinyMessageEditorView({ controller, tags, onTagClick, editorHeight }) {
  const { translate } = useLanguageContext();

  return (
    <StyledTinyMessageEditor>
      <StyledFlexItem>
        <TinyEditor controller={controller} />
      </StyledFlexItem>

      <OverlayLoader groups="tiny_message_editor_fetch">
        <StyledTagsContainer style={{ height: `${editorHeight}px` }}>
          <StyledTagsHeader>
            <FontAwesomeIcon icon={faTags} size="lg" />
            <b>{translate("eva.main.general.tags")}</b>
          </StyledTagsHeader>

          <StyledTagsContent>
            {tags.map((tag) => (
              <StyledTagButton key={tag} onClick={() => onTagClick(tag)}>
                <StyledTagLabel>{tag}</StyledTagLabel>
              </StyledTagButton>
            ))}
          </StyledTagsContent>

          <StyledTagsFooter />
        </StyledTagsContainer>
      </OverlayLoader>
    </StyledTinyMessageEditor>
  );
}

export default TinyMessageEditorView;

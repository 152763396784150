import Layout from "../../../../components/layout/Layout";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledAltButton } from "../../../../styles/ButtonStyles";
import { StyledFlexColumn } from "../../../../styles/LayoutStyles";
import { StartPageConfig } from "../start/StartPage";

function ButtonPageView({ button, layout, setPageConfig }) {
  const { translateEntity, translate } = useLanguageContext();

  return (
    <Layout
      layoutData={layout}
      content={
        <StyledFlexColumn>
          <p
            dangerouslySetInnerHTML={{
              __html: translateEntity(button, "message"),
            }}
          />

          <StyledAltButton onClick={() => setPageConfig(StartPageConfig)}>
            {translate("eva.frontoffice.general.back")}
          </StyledAltButton>
        </StyledFlexColumn>
      }
    />
  );
}

export default ButtonPageView;

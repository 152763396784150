class DashboardNavButtonConfig {
  constructor() {
    this.icon = null;
    this.routeConfig = null;
    this.onlyActiveOnExactPath = false;
    this.activePathExceptions = [];
    this.hidden = false;
  }

  /**
   * @param {RouteConfig} routeConfig
   * @returns {DashboardNavButtonConfig}
   */
  setRouteConfig(routeConfig) {
    this.routeConfig = routeConfig;
    return this;
  }

  /**
   * @param {string} icon
   * @returns {DashboardNavButtonConfig}
   */
  setIcon(icon) {
    this.icon = icon;
    return this;
  }

  /**
   * @param {boolean} onlyActiveOnExactPath
   * @returns {DashboardNavButtonConfig}
   */
  setOnlyActiveOnExactPath(onlyActiveOnExactPath) {
    this.onlyActiveOnExactPath = onlyActiveOnExactPath;
    return this;
  }

  /**
   * @param {string[]} activePathExceptions
   * @returns {DashboardNavButtonConfig}
   */
  setActivePathExceptions(activePathExceptions) {
    this.activePathExceptions = activePathExceptions;
    return this;
  }

  /**
   * @param {boolean} hidden
   * @returns {DashboardNavButtonConfig}
   */
  setHidden(hidden) {
    this.hidden = hidden;
    return this;
  }

  /**
   * This method will return the disabled state of the button
   * @returns {boolean}
   */
  getDisabled() {
    if (this.routeConfig.validator) {
      return this.routeConfig.validator.validate() !== true;
    }
    return false;
  }

  /**
   * This method will determine if the button should be active
   * @param {string} location
   * @returns {boolean}
   */
  getActive(location) {
    if (!location) {
      return false;
    }

    let active = location.pathname.startsWith(this.routeConfig.path);

    if (this.onlyActiveOnExactPath) {
      active = location.pathname.endsWith(this.routeConfig.path);
    } else {
      this.activePathExceptions.forEach((exception) => {
        active = location.pathname.includes(exception) ? false : active;
      });
    }

    return active;
  }
}

export default DashboardNavButtonConfig;

import {
  ThemeProvider as OriginalThemeProvider,
  useTheme,
} from "styled-components";
import DarkTheme from "../themes/DarkTheme";
import { createContext, useContext, useMemo, useState } from "react";
import useEvaApi, { EvaApiCall } from "../hooks/useEvaApi";
import { useAppContext } from "./AppContext";
import useCustomEffect from "../hooks/useCustomEffect";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";
import LightTheme from "../themes/LightTheme";

export let FRONTOFFICE_THEME = false;

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const callEvaApi = useEvaApi();
  const { contexts } = useAppContext();

  const availableThemes = useMemo(
    () => [
      {
        id: "dark",
        theme: DarkTheme,
      },
      {
        id: "light",
        theme: LightTheme,
      },
    ],
    []
  );

  const [data, setData] = useState({
    theme: localStorage.getItem("theme") ?? "dark",
    settings: null,
  });
  const theme = {
    ...availableThemes.find((t) => t.id === data.theme)?.theme,
    ...data.settings,
  };

  /**
   * UseEffect for fetching the theme settings
   */
  useCustomEffect(() => {
    fetchThemeSettings();
  }, [
    contexts.authContext?.auth?.user?.role_type,
    contexts.authContext?.auth?.location,
    contexts.terminalContext?.terminal,
  ]);

  useCustomEffect(() => {
    FRONTOFFICE_THEME = data.settings !== null;
  }, [data.settings]);

  /**
   * This method will fetch the theme settings from the API
   */
  async function fetchThemeSettings() {
    if (
      contexts.authContext?.auth?.user?.role_type !== "terminal" ||
      !contexts.terminalContext?.terminal
    ) {
      return;
    }

    await callEvaApi(
      new EvaApiCall("theme_settings")
        .setLoadingGroup("APP")
        .setBlockAppRender(true)
        .setOnSuccess((response) => {
          setData((prev) => {
            const newSettings = {};
            response.data.data.records.forEach((record) => {
              newSettings[`settings.${record.key}`] = record.value;
            });

            return {
              ...prev,
              settings: newSettings,
            };
          });
        })
    );
  }

  /**
   * This method will handle the theme change
   * @param {object} themeId
   */
  function onThemeChange(theme) {
    localStorage.setItem("theme", theme);
    setData((prev) => ({
      ...prev,
      theme: theme,
    }));
  }

  return useContextDefiner(
    new ContextDefinition("themeContext", ThemeContext)
      .setData({ onThemeChange, availableThemes, theme: data.theme })
      .setChildren(
        <OriginalThemeProvider theme={theme}>{children}</OriginalThemeProvider>
      )
  );
}

export function useThemeContext() {
  return useContext(ThemeContext);
}

export class MultiStyle {
  constructor(props, attribute) {
    this.props = props;
    this.attribute = attribute;
    this.frontoffice = "";
    this.backoffice = "";
  }

  setFront(value) {
    this.frontoffice = value;
    return this;
  }

  setBack(value) {
    this.backoffice = value;
    return this;
  }

  get() {
    let value = this.props.theme.FRONTOFFICE
      ? this.frontoffice
      : this.backoffice;

    return value && `${this.attribute}: ${value};`;
  }

  static new(props, attribute) {
    return new MultiStyle(props, attribute);
  }
}

export function Themed({ children, frontoffice = false }) {
  const theme = useTheme();
  return (
    <OriginalThemeProvider theme={{ ...theme, FRONTOFFICE: frontoffice }}>
      {children}
    </OriginalThemeProvider>
  );
}

import { useState } from "react";
import { faCheck, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledInput } from "../../../styles/InputStyles";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import RowActionConfig from "../main/config/rowAction/RowActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";
import HtmlColorInput from "../../input/html/color/HtmlColorInput";
import Select from "../../select/main/Select";

function ThemeSettingsDataTable() {
  const callEvaApi = useEvaApi();
  const { translate } = useLanguageContext();
  const [editRow, setEditRow] = useState(null);

  //Create the datatable config
  const config = new DataTableConfig("theme_settings");

  //Set the datatable fetch info
  config.setFetchRequest("theme_settings");

  //Set the datatable order by
  config.setOrderBy("key.translation");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("key.translation")
      .setData((row) => translate(`eva.main.theme_settings.${row.key}`))
      .setLockActive(true),
    useBasicColumnConfig("value")
      .setData(getValueColumnData)
      .setLockActive(true)
      .setSearchable(false)
      .setOrderable(false),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    new RowActionConfig()
      .setIcon(faPen)
      .setCallback(handleEdit)
      .setHidden((row) => row.id === editRow?.id)
      .setTitle(
        translate("eva.main.entities.states.edit", [
          {
            key: "entities",
            value: translate("eva.main.entities.theme_setting"),
          },
        ])
      ),
    new RowActionConfig()
      .setIcon(faXmark)
      .setCallback(handleCancel)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.cancel")),
    new RowActionConfig()
      .setIcon(faCheck)
      .setCallback(handleConfirm)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.save")),
  ]);

  //Set the overall loading groups for the datatable row actions
  config.setRowActionsLoadingGroups(
    (row) => `${config.getDataTableKey()}_update_${row.id}`
  );

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This method will return the data representation of the value column
   * @param {object} row
   * @returns {string|any}
   */
  function getValueColumnData(row) {
    //Color input
    if (row.type.includes("color")) {
      return (
        <HtmlColorInput
          value={editRow?.id === row.id ? editRow.value : row.value}
          onChange={(value) => setEditRow({ ...editRow, value })}
          useOpacityInput={row.type.includes("rgba")}
          useVisabilityInput={row.type.includes("nullable")}
          disabled={editRow?.id !== row.id}
        />
      );
    }

    //Number input
    if (row.type === "number") {
      return editRow?.id !== row.id ? (
        row.value
      ) : (
        <StyledInput
          type="number"
          value={editRow?.id === row.id ? editRow.value : row.value}
          onChange={(e) => setEditRow({ ...editRow, value: e.target.value })}
          width={"100%"}
        />
      );
    }

    //Font input
    if (row.type === "font") {
      return (
        <Select
          value={editRow?.id === row.id ? editRow.value : row.value}
          onChange={(value) => setEditRow({ ...editRow, value })}
          searchable={false}
          options={[
            { value: "Roboto", label: "Roboto" },
            { value: "Arial", label: "Arial" },
            { value: "Open Sans", label: "Open Sans" },
            { value: "Verdana", label: "Verdana" },
            { value: "Tahoma", label: "Tahoma" },
            { value: "Trebuchet MS", label: "Trebuchet MS" },
            { value: "Calibri", label: "Calibri" },
            { value: "Comic Sans MS", label: "Comic Sans MS" },
            { value: "Century Gothic", label: "Century Gothic" },
            { value: "Monospace", label: "Monospace" },
            { value: "Montserrat", label: "Montserrat" },
          ]}
          disabled={editRow?.id !== row.id}
        />
      );
    }

    //Boolean input
    if (row.type === "boolean") {
      return (
        <Select
          value={editRow?.id === row.id ? editRow.value : row.value}
          onChange={(value) => setEditRow({ ...editRow, value })}
          options={[
            { value: true, label: translate("eva.main.general.yes") },
            { value: false, label: translate("eva.main.general.no") },
          ]}
          disabled={editRow?.id !== row.id}
        />
      );
    }

    //default input
    return row.value;
  }

  /**
   * This method will start the edit state of the given row
   * @param {object} row
   */
  function handleEdit(row) {
    setEditRow(row);
  }

  /**
   * This method will cancel the update of the given row
   * @param {object} row
   */
  function handleCancel(row) {
    setEditRow(null);
  }

  /**
   * This method will send a request to the API for updating the given row
   * @param {object} row
   */
  async function handleConfirm(row) {
    if (editRow.value === row.value) {
      setEditRow(null);
      return;
    }

    await callEvaApi(
      new EvaApiCall(`theme_settings/${row.id}`)
        .setMethod("PUT")
        .setData({ value: editRow.value })
        .setLoadingGroup(`${config.getDataTableKey()}_update_${row.id}`)
        .setOnSuccess((response) => {
          row.id = response.data.data.id;
          row.value = response.data.data.value;
          setEditRow(null);
        })
    );
  }

  return <DataTable controller={dataTable} />;
}

export default ThemeSettingsDataTable;

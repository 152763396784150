import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledSelectInput,
  StyledSelectOption,
  StyledSelectOptions,
  StyledSelectValue,
  StyledSelectValueWrapper,
  StyledSelectWrapper,
} from "./SelectStyles";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../context/LanguageContext";
import ReactDOM from "react-dom";

function SelectView({
  value,
  search,
  searchable,
  disabled,
  focused,
  options,
  searchRef,
  selectRef,
  optionsRef,
  optionsPortal,
  onSearchChange,
  onValueChange,
  onSearchConfirm,
  onSearchFocus,
  onSearchBlur,
  onSelectClick,
  as: Element = StyledSelectWrapper,
  hideCaret = false,
  valueVisual = (option) => option?.label,
  placeholder,
  title,
  style,
}) {
  const { translate } = useLanguageContext();

  return (
    <>
      <Element
        ref={selectRef}
        disabled={disabled}
        title={title}
        style={style}
        onClick={onSelectClick}
      >
        <StyledSelectValueWrapper>
          <StyledSelectValue>
            {valueVisual(value) ??
              placeholder ??
              translate("eva.main.general.select")}
          </StyledSelectValue>

          <StyledSelectInput
            ref={searchRef}
            value={search}
            disabled={disabled}
            onChange={onSearchChange}
            onKeyDown={onSearchConfirm}
            onFocus={onSearchFocus}
            onBlur={onSearchBlur}
            hide={`${!focused || !searchable}`}
          />
        </StyledSelectValueWrapper>

        {!hideCaret && <FontAwesomeIcon icon={faCaretDown} size="sm" />}

        {optionsPortal &&
          ReactDOM.createPortal(
            <StyledSelectOptions ref={optionsRef} hide={`${!focused}`}>
              {focused &&
                options.map((option) => (
                  <StyledSelectOption
                    key={option.value}
                    onClick={() => onValueChange(option.value)}
                    active={`${value?.value === option.value}`}
                    disabled={option.disabled ?? false}
                  >
                    {option.label}
                  </StyledSelectOption>
                ))}

              {focused && options.length < 1 && (
                <StyledSelectOption>
                  {translate("eva.main.general.no_results")}
                </StyledSelectOption>
              )}
            </StyledSelectOptions>,
            optionsPortal
          )}
      </Element>
    </>
  );
}

export default SelectView;

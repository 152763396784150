import { useTheme } from "styled-components";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import Modal from "../../../components/modal/Modal";
import Select from "../../../components/select/main/Select";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledAltButton } from "../../../styles/ButtonStyles";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";
import { useTerminalContext } from "../../../context/TerminalContext";
import { useAuthContext } from "../../../context/AuthContext";
import { Themed } from "../../../context/ThemeContext";

function SettingsView({ open, onClose }) {
  const theme = useTheme();
  const terminalContext = useTerminalContext();
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  return (
    open && (
      <Themed frontoffice={false}>
        <Modal
          title={translate("eva.main.general.settings")}
          onClose={terminalContext.terminal ? onClose : null}
        >
          <StyledFlexColumn>
            <InputWrapper
              label={translate("eva.main.validation.attributes.terminal_id")}
              required
            >
              <Select
                options={terminalContext.terminals.map((terminal) => ({
                  value: terminal.id,
                  label: terminal.name,
                }))}
                value={terminalContext.terminal?.id}
                onChange={terminalContext.updateTerminal}
              />
            </InputWrapper>

            <StyledAltButton
              onClick={() => authContext.logout()}
              style={{ marginTop: theme.spacing.normal }}
            >
              {translate("eva.main.auth.logout")}
            </StyledAltButton>
          </StyledFlexColumn>
        </Modal>
      </Themed>
    )
  );
}

export default SettingsView;

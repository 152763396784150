import styled from "styled-components";

export const StyledNode = styled.div`
  border-radius: ${(props) => props.theme.radius.normal};
  background-color: ${(props) => props.theme.colors.primary.normal};
  padding: ${(props) => props.theme.spacing.normal};
  min-width: 200px;
  border: 1px solid
    ${(props) =>
      props.focused ? props.theme.colors.accent.normal : "transparent"};

  &:hover {
    border-color: ${(props) =>
      props.onClick ? props.theme.colors.accent.dark : "transparent"};
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  }
`;

export const StyledNodeTitle = styled.p`
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing.normal};
`;

import styled from "styled-components";
import { StyledButton } from "../../styles/ButtonStyles";

export const StyledFilePreview = styled.div`
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  display: inline-block;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  gap: ${(props) => props.theme.spacing.normal};
  padding: ${(props) => props.theme.spacing.large};
  cursor: pointer;
`;

export const StyledPreviewContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

export const StyledPreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

export const StyledMiniPreview = styled.img`
  width: ${(props) => props.theme.container.size.normal};
  height: ${(props) => props.theme.container.size.normal};
  min-width: ${(props) => props.theme.container.size.normal};
  min-height: ${(props) => props.theme.container.size.normal};
  object-fit: cover;
`;

export const StyledCloseButton = styled(StyledButton)`
  width: 300px;
  max-width: 100%;
`;

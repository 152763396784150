import CalamityToolsDataTable from "../../../components/dataTable/tables/calamities/CalamityToolsDataTable";
import CalamityTypesDataTable from "../../../components/dataTable/tables/calamities/CalamityTypesDataTable";
import CalamityTable from "../../../components/calamityTable/CalamityTable";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";
import { useTheme } from "styled-components";

function CalamitiesPageView() {
  const theme = useTheme();

  return (
    <StyledFlexColumn style={{ gap: theme.spacing.large }}>
      <CalamityTable />
      <CalamityToolsDataTable />
      <CalamityTypesDataTable />
    </StyledFlexColumn>
  );
}

export default CalamitiesPageView;

import { useTheme } from "styled-components";
import { useLanguageContext } from "../../context/LanguageContext";
import { StyledFlexColumn } from "../../styles/LayoutStyles";
import Modal from "../modal/Modal";
import {
  StyledQuestionnaire,
  StyledQuestionnaireWrapper,
  StyledQuestionWrapper,
} from "./VisitorQuestionsDisplayStyles";

function VisitorQuestionsDisplayView({
  visitor,
  loadingGroup,
  questionnaires,
  onClose,
}) {
  const theme = useTheme();
  const { translateEntity, translate } = useLanguageContext();

  return (
    visitor && (
      <Modal
        title={translate("eva.main.entities.questions")}
        onClose={onClose}
        loadingGroups={loadingGroup}
      >
        <StyledFlexColumn style={{ gap: theme.spacing.large }}>
          {Object.keys(visitor.questions).map((questionnaireKey) => {
            const questionnaire = questionnaires.find(
              (q) =>
                q.name.toLowerCase() === questionnaireKey.toLocaleLowerCase()
            );

            if (!questionnaire) return "";

            return (
              <StyledQuestionnaireWrapper key={questionnaireKey}>
                <StyledQuestionnaire>{questionnaire.name}</StyledQuestionnaire>

                <StyledFlexColumn>
                  {Object.keys(visitor.questions[questionnaireKey]).map(
                    (questionKey) => {
                      const question = questionnaire.questions.find(
                        (q) =>
                          q.name.toLowerCase() === questionKey.toLowerCase()
                      );

                      if (!question) return "";

                      const answer = question.answers.find(
                        (a) =>
                          a.name.toLowerCase() ===
                          visitor.questions[questionnaireKey][
                            questionKey
                          ].toLowerCase()
                      );

                      if (!answer) return "";

                      return (
                        <StyledQuestionWrapper
                          key={questionnaireKey + questionKey}
                        >
                          <p>
                            <b>{translateEntity(question, "question")}</b>
                          </p>
                          <p>{translateEntity(answer, "answer")}</p>
                        </StyledQuestionWrapper>
                      );
                    }
                  )}
                </StyledFlexColumn>
              </StyledQuestionnaireWrapper>
            );
          })}
        </StyledFlexColumn>
      </Modal>
    )
  );
}

export default VisitorQuestionsDisplayView;

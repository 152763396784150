import { StyledFlexRow } from "../../../../../../styles/LayoutStyles";
import {
  StyledAltButton,
  StyledButton,
} from "../../../../../../styles/ButtonStyles";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import { StartPageConfig } from "../../../start/StartPage";
import HiddenLoader from "../../../../../../components/loader/HiddenLoader/HiddenLoader";
import { useLoadContext } from "../../../../../../context/LoadContext";
import { StyledStepLayout } from "./StepLayoutStyles";

function StepLayoutView({
  children,
  step,
  setPageConfig,
  nextStep,
  previousStep,
  proceedDisabled,
}) {
  const loadContext = useLoadContext();
  const { translate } = useLanguageContext();

  return (
    <StyledStepLayout>
      {children}

      {/* Actions */}
      <StyledFlexRow>
        {/* Cancel */}
        <StyledAltButton
          onClick={() => setPageConfig(StartPageConfig)}
          disabled={loadContext.isLoading([
            "process_step",
            "previous_step",
            "create_visitor",
          ])}
          style={{ flex: 1 }}
        >
          {translate("eva.frontoffice.general.cancel")}
        </StyledAltButton>

        {/* Back */}
        {step.order > 0 && (
          <HiddenLoader groups={"previous_step"} containerStyle={{ flex: 1 }}>
            <StyledAltButton
              style={{ width: "100%" }}
              disabled={loadContext.isLoading([
                "process_step",
                "create_visitor",
              ])}
              onClick={() => previousStep()}
            >
              {translate("eva.frontoffice.general.back")}
            </StyledAltButton>
          </HiddenLoader>
        )}

        {/* Proceed */}
        <HiddenLoader
          groups={["process_step", "create_visitor"]}
          containerStyle={{ flex: 1 }}
        >
          <StyledButton
            style={{ width: "100%" }}
            disabled={
              loadContext.isLoading(["previous_step", "create_visitor"]) ||
              proceedDisabled
            }
            onClick={nextStep}
          >
            {translate("eva.frontoffice.general.proceed")}
          </StyledButton>
        </HiddenLoader>
      </StyledFlexRow>
    </StyledStepLayout>
  );
}

export default StepLayoutView;
